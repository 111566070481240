<div class="content" *ngIf="d">

    <!-- A17+ -->
    @if (matCard) {
        <mat-card>
            <div class="content2">
                <mat-card-subtitle>
                    <ng-container *ngTemplateOutlet="Header"></ng-container>
                </mat-card-subtitle>

                <mat-card-content>
                    <ng-container *ngTemplateOutlet="Content"></ng-container>
                </mat-card-content>
            </div>
        </mat-card>
    }
    @else {
        <h4 class="mat-subheading-1">
            <ng-container *ngTemplateOutlet="Header"></ng-container>
        </h4>

        <ng-container *ngTemplateOutlet="Content"></ng-container>
    }


    <!-- <ng-template [ngIf]="matCard" [ngIfThen]="useCard" [ngIfElse]="noCard"></ng-template> -->

    <!-- <mat-card-subtitle *ngIf="matCard">Mobile Cell<qp-mobilecell-technology-icon [technology]="technology"></qp-mobilecell-technology-icon></mat-card-subtitle>
    <h4 *ngIf="! matCard" class="mat-subheading-1">Mobile Cell<qp-mobilecell-technology-icon [technology]="technology"></qp-mobilecell-technology-icon></h4> -->

    <!-- <ng-template #useCard>
        <mat-card>
            <div class="content2">
                <mat-card-subtitle>
                    <ng-container *ngTemplateOutlet="Header"></ng-container>
                </mat-card-subtitle>

                <mat-card-content>
                    <ng-container *ngTemplateOutlet="Content"></ng-container>
                </mat-card-content>
            </div>
        </mat-card>
    </ng-template>


    <ng-template #noCard>
        <h4 class="mat-subheading-1">
            <ng-container *ngTemplateOutlet="Header"></ng-container>
        </h4>

        <ng-container *ngTemplateOutlet="Content"></ng-container>
    </ng-template> -->


    <ng-template #Header>
        Wifi AP
        <qp-wificell-version-icon *ngIf="showVersion" class="icon" [cell]="d"></qp-wificell-version-icon>
        <!-- <qp-wificell-version-icon *ngIf="showVersion" class="icon" [version]="version()"></qp-wificell-version-icon> -->
        <!-- <qp-wificell-level-icon      *ngIf="showLevel"      class="icon icon-1-5d" [cell]="d">                                               </qp-wificell-level-icon> -->
    </ng-template>

    <ng-template #Content>
        <!-- <p></p>
        
        <h4 class="mat-subheading-1">{{ data.tag | titlecase }}</h4> -->
        <!-- <mat-list dense> -->

        <!-- <qp-table-data *ngIf="d" [nullValues]=false [data]=" -->
        <qp-table-data *ngIf="d" [nullValues]=true [data]="listObj(d)">
<!-- [
    { k: 'Ssid',          v: d.ssid  ? d.ssid  : ''                            },
    { k: 'Bssid',         v: d.bssid ? d.bssid : ''                            },
    { k: 'Version',       v: versionStr | titlecase, c: 'wifi-' + d.standard   },
    { k: 'Band',          v: d.band  ? d.band.name : ''                        },
    { k: 'Channel',       v: d.channel                                         },
    { k: 'Channel Width', v: d.channelwidth ? d.channelwidth + ' MHz' : ''     },
    { k: 'Frequency',     v: d.frequencydl ? d.frequencydl + ' MHz' : ''       },
    { k: 'Type',          v: d.standard                                        },
    { k: 'Rssi',          v: d.rssi  ? d.rssi + ' dBm' : ''                    },
]> -->
        
            <!-- { k: 'Ssid',          v: d.ssid  ? d.ssid  : '<Unknown>'                   },
            { k: 'Bssid',         v: d.bssid ? d.bssid : '<Unknown>'                   }, -->
            <!-- { k: 'Security',      v: d.security                                        } -->

        </qp-table-data>
        <!-- { k: 'Connected', v: d.connected ? 'Yes' : '', c: d.connected ? 'wifi-connected' : ''  }, -->


    </ng-template>
</div>