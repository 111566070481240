<div class="div" *ngIf="d">
    <mat-card *ngIf="matCard">

        <mat-card-subtitle *ngIf="matCard">
            <qp-notification-icon [state]="state"></qp-notification-icon>
            Notification {{ d.id }}
        </mat-card-subtitle>
        <h4 *ngIf="! matCard" class="mat-subheading-1">
            <qp-notification-icon [state]="state"></qp-notification-icon>
            Notification {{ d.id }}
        </h4>


    <!-- <mat-list dense>
        <qp-data-element-item [light]="light" [key]="'Id'"          [text]="d.id">         </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Date'"        [text]="d.date">       </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Name'"        [text]="d.name">       </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Description'" [text]="d.description"></qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Severity'"    [text]="d.severity">   </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'System Id'"   [text]="d.systemid">   </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'System Type'" [text]="d.systemType"> </qp-data-element-item>
    </mat-list>
 -->

        <mat-card-content *ngIf="matCard">
            <qp-table-data
                [nullValues]="nullValues" [data]="
                    [
                        { k: 'Date',        v: d.dateStr              },
                        { k: 'Description', v: d.name     | titlecase },
                        { k: 'Severity',    v: d.severity | titlecase },
                        { k: 'System Id',   v: d.systemId             },
                        { k: 'Detail',      v: d.info     | titlecase }
                    ]">
                    <!-- { k: 'System Type', v: d.systemType  } -->
                </qp-table-data>
        </mat-card-content>
    </mat-card>
</div>  