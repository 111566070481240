import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { SurveysService }       from '@Surveys/service/';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
   selector:    'qp-sidebar-surveys',
   templateUrl: 'sidebar-surveys.component.html',
   styles:      'sidebar-surveys.component.css'
})
export class SideBarSurveysComponent extends SideBarBaseComponent
{
    constructor(private readonly SurveysService: SurveysService)
    {
        super(IconsMaterial.surveys, SurveysService, true);
    }
}