<qp-table-data *ngIf="d"
    [nullValues]="nullValues"
    [data]="
        [

        ]">
</qp-table-data>


<!-- <div class="div" *ngIf="d">

    <mat-list dense>
        <qp-data-element-item                     [light]="light" [key]="'Enodeb Id'"     [text]="d.ecid"> </qp-data-element-item>
        <qp-data-element-item                     [light]="light" [key]="'Local cell Id'" [text]="d.lcid"> </qp-data-element-item>
        <qp-data-element-item *ngIf="d.pci >= 0"  [light]="light" [key]="'Pci'"           [text]="d.pci">  </qp-data-element-item>
        <qp-data-element-item *ngIf="d.tac >= 0"  [light]="light" [key]="'Tac'"           [text]="d.tac">  </qp-data-element-item>
        <qp-data-element-item                     [light]="light" [key]="'Rsrp'"          [text]="d.rsrp"> </qp-data-element-item>
        <qp-data-element-item                     [light]="light" [key]="'Rsrq'"          [text]="d.rsrq"> </qp-data-element-item>
        <qp-data-element-item                     [light]="light" [key]="'Rssnr'"         [text]="d.rssnr"></qp-data-element-item>
        <qp-data-element-item *ngIf="d.asu > 0"   [light]="light" [key]="'Asu'"           [text]="d.asu">  </qp-data-element-item>
        <qp-data-element-item *ngIf="d.cqi > 0"   [light]="light" [key]="'Cqi'"           [text]="d.cqi">  </qp-data-element-item>
        <qp-data-element-item *ngIf="d.ta > 0"    [light]="light" [key]="'Ta'"            [text]="d.ta">   </qp-data-element-item>
    </mat-list>

</div>   -->