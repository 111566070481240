import { Injectable }                 from '@angular/core';
import { Observable }                 from 'rxjs';

import { MessageService }             from '@Messaging/';
import { DataService }                from '@Misc/Services/';

import { WifiCell as WifiCellCommon } from '@Common/';
 

@Injectable({
    providedIn: 'root'
})
export class WifiApsService extends DataService
{
    // See IEEE 802.11g-2003
    private static readonly WifiChannels80211g = new Map([
        [ 1,  2412 ],
        [ 2,  2417 ],
        [ 3,  2422 ],
        [ 4,  2427 ],
        [ 5,  2432 ],
        [ 6,  2437 ],
        [ 7,  2442 ],
        [ 8,  2447 ],
        [ 9,  2452 ],
        [ 10, 2457 ],
        [ 11, 2462 ],
        [ 12, 2467 ],
        [ 13, 2472 ], 
        [ 14, 2484 ] 
    ]); // WifiChannels80211g
 
    protected static readonly _title: string = "Wifi";


    public constructor(MessageService: MessageService)
    { 
        super(MessageService, WifiApsService._title, MessageService.messages.msgTypesSub.wifiaps);

        this.refresh(); // default to quantityOnly this initially

        // // Listen for events
        // this.sub = this.MessageProcessingService.getObs$(Messages.events.mobilecells)
        //     .subscribe((d: any): void => {
        //         this.process(d, true);
        //     }); // subscribe
    }


    //
    // Interface
    //

    // Override    
    public get(id: string | number): object | undefined
    {
        return this.getEl(id as string);
    }


    // Override
    protected override processMsgData(d: any[]): void
    {
        super.processMsgData(d);

        if (d && Array.isArray(d)) {
            console.log("Updating " + this.Title + " service with data: " + d.length);
            this.clear(false);
            Object.values(d).forEach((v): void => {
                this.process(v, false);
            }); // forEach
            this.updateObservables(false);
        }
        else {
            console.log("No data received to update " + this.Title + " service");
        }
    }


    //
    // Static methods
    //
    public static getWifiChannel80211g(frequency: number): string
    {
        const entry = [...WifiApsService.WifiChannels80211g].find(([k, v]): boolean => frequency == v);
        const s: number | undefined = entry ? entry[0] : undefined;

        // const s: string | undefined = WifiApsService.WifiChannels80211g.keys().find((d: string): boolean => {
        //     return !!d && frequency == WifiApsService.getWifiFrequency80211g.get(d);
        // }) // keys

        return s ? s.toString(s) : "";
    }


    public static getWifiFrequency80211g(ch: number | string): number | undefined
    {
        const i: number = ! Number.isNaN(ch) ? ch as number : Number(ch); 
        return i ? WifiApsService.WifiChannels80211g.get(i) : undefined;
    }



    //
    // Private methods
    //
    private getEl(id: string): object | undefined// WifiCellCommon
    {
        return this.getElement(id);// as WifiCellCommon;
    }


    // private deleteCell(bssid: string, update: boolean = true): boolean
    // {
    //     if (this.getElement(bssid)) {
    //     //if (i != null && i >= 0 && i < _cells.length) {
    //     //    _cells.splice(i, 1);
    //         console.log("Wifi AP '" + bssid + "' found and deleted");
    //         return this.del(bssid, update);

    //     }
    //     else {
    //         console.log("Wifi AP '" + bssid + "' not found; cannot delete");
    //     }

    //     return false;
    // }


    private process(d: any, update: boolean): void
    {
        if (d && d.bssid) {
            this.add(d.bssid, d, update);
            if (update) this.updateObservables();
        }

        // [TBD]
        // const s: WifiCellCommon = WifiCellCommon.get(d);
        // if (s) {
        //     this.add(s.id, s);
        //     if (update) this.updateObservables();
        // }
    }


    private updateCell(cell: any): boolean
    {
        if (cell && this.getElement(cell.bssid)) {
            this.dataI[cell.bssid as string] = {...this.dataI[cell.bssid as string], ...cell}; // spread op, replaces Object.assign()
            return true;
        }

        return false;
    }


    //
    // Private methods
    //
    // public refresh2(): Subscription
    // {
    //     console.log("Refreshing Wifi service - getting all wifi APs");
    //     this.dataLoading$.next(true);
 
    //     // Use observable
    //     const obs: Observable<any> = this.MessageService.sendMsg(
    //         this.MessageService.messages.msgTypes.getdata,
    //         {
    //             type: this.MessageService.messages.msgTypesSub.wifiaps
    //         }
    //     );

    //     if (obs) {
    //         return (this.sub = obs
    //             .pipe(take(1))
    //             .subscribe((data: any): void => {
    //                 this.update(data.cells);
    //                 this.dataLoading$.next(false);
    //             })
    //         );
    //     }

    //     return null;
    // }
}