<div (click)="select(data)">
    <div class="div" *ngIf="data">
        <!-- <div *ngIf="data.lastUpdatedDateStr && showUpdated">
            <h5 class="mat-h5">Updated: {{ data.lastUpdatedDateStr }}</h5>

            <p></p>
        </div> -->

        <!-- <div class="padding" *ngIf="showUpdated"> -->
        <div *ngIf="showUpdated">
            <qp-table-data
                [nullValues]=false [data]="
                    [
                        showUpdated
                            ? { k: 'Updated', v: data.lastUpdatedDateStr }
                            : {}
                    ]">
            </qp-table-data>
        </div>

        <qp-table-data
            [nullValues]=false [data]="
                [
                    showType
                        ? { k: 'Type',        v: data.tag         | titlecase  }
                        : {},

                    showId
                        ? { k: 'Id',          v: data.id + ''                  }
                        : {},

                    showDescription
                        ? { k: 'Description', v: data.description | titlecase  }
                        : {},

                    showLocation
                        ? { k: 'Location',    v: data.location | titlecase  }
                        : {},
                ]">
        </qp-table-data>
        
        <!-- <mat-list dense>
            <qp-data-element-item *ngIf="showUpdated && data.lastUpdatedDateStr" [light]="light" [key]="'Updated'"     [text]="data.lastUpdatedDateStr">     </qp-data-element-item>
            <qp-data-element-item *ngIf="showType"                               [light]="light" [key]="'Type'"        [text]="data.tag         | titlecase"></qp-data-element-item> -->
            <!-- <qp-data-element-item                                                [light]="light" [key]="'Type'"        [text]="data.tag         | titlecase"></qp-data-element-item>                  </qp-data-element-item> -->
            <!-- <qp-data-element-item *ngIf="showId"                                 [light]="light" [key]="'Id'"          [text]="data.id">                     </qp-data-element-item>
            <qp-data-element-item                                                [light]="light" [key]="'Description'" [text]="data.description | titlecase"></qp-data-element-item>
            <qp-data-element-item                                                [light]="light" [key]="'Location'"    [text]="data.location    | titlecase"></qp-data-element-item> -->
            <!-- <qp-data-element-item *ngIf="data.geolocation"                       [light]="light" [key]="'GeoLocation'" [text]="data.geolocationStr"></qp-data-element-item> -->
        <!-- </mat-list> -->
    </div>
</div>