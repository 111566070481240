import {
    Component,
    Input
}                        from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../element/element/element-info.component';

import { Organisation }         from './organisation.class';

import { routeNames }           from '../../app.routing.names';


@Component({
    selector:     'qp-organisation-info',
    templateUrl:  'organisation-info.component.html',
    styleUrls:   ['organisation-info.component.css']
})
export class OrganisationInfoComponent extends ElementInfoComponent
{
    @Input()
    public showName: boolean = true;


    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): Organisation
    {
        return this.data as Organisation;
    }

    //
    // Public methods
    //
    public selectedFn(d: any): Promise<boolean> | undefined
    {
        // Now route to main org page
        return d instanceof Organisation && (d as Organisation).id
            ? this.Router.navigate(
                [ routeNames.organisations, (d as Organisation).id ],
                { state: { parent: this.Router.url } }
            )
            : undefined;


            this.Router.navigate([ routeNames.map ], { queryParams: { 'element': Organisation.tag, 'id': this.data.id } });
    }
}