import {
    Pipe,
    PipeTransform
}                     from '@angular/core';

import { MobileCell } from 'ObjElements/';


@Pipe({
    name: 'cellsOrder',
    pure: false
})
export class SurveyPointCellsOrderPipe implements PipeTransform
{
    transform(v: MobileCell[]): MobileCell[]
    {
        return v?.sort((o1: MobileCell, o2: MobileCell) => {
            if      (o1?.technology < o2?.technology) return -1;
            else if (o1?.technology > o2?.technology) return 1;
            else                                      return 0;
        }); // sort()
    }
}