'use strict';


//
// Note: the contents below *MUST* match those in the
// (Java) colours XML file (./res/values/color.xml)
//

// Deprecated?


const _black            = '#ff000000';
const _blue_light_sfn   = '#ff0eb7f6';
const _green_sfn        = '#ff00ff99';
const _green_dark_sfn   = '#ff037044';
const _green_dark_2_sfn = '#ff024002';
const _mauve_sfn        = '#ff945555';
const _orange_sfn       = '#ffea5f39';
const _red_dark_sfn     = '#ff940303';
const _yellow_sfn       = '#ffffff66';
const _yellow_dark_sfn  = '#ffB18516';


const _mobileSignalColour = {
    great:   _green_dark_sfn,
    good:    _green_dark_2_sfn,
    average: _yellow_dark_sfn,
    poor:    _mauve_sfn,
    bad:     _red_dark_sfn,
    none:    _black
};


const _mobileTechnologyColour = {
    cdma:    _black,
    gsm:     _green_sfn,
    lte:     _blue_light_sfn,
    lte_nr:  _mauve_sfn,
    nr:      _orange_sfn,
    tdscdma: _black,
    umts:    _yellow_sfn,
    unknown: _black
};


export class Colours
{
    // Comment out so can only use class as static
    // constructor()
    // {

    // }

    
    static get mobileSignalColour()
    {
        return _mobileSignalColour;
    }


    static get mobileTechnologyColour()
    {
        return _mobileTechnologyColour;
    }
}