<div (click)="onClick($event)"
    matTooltip="{{ name + (showBadge && num ? ': ' + num : '') }}">

    <button mat-button [color]="active ? buttonActive : ''">
        <mat-icon [ngClass]="showLabel ? '' : 'noLabel'" [matBadge]="num" [matBadgeColor]="badgeColour" [matBadgePosition]="badgePosition" [matBadgeSize]="badgeSize" [matBadgeHidden]="! showBadge || num <= 0">
            
        <!-- </mat-icon> [aria-label]="{{ name }} view"> -->
            {{ icon }}
        </mat-icon>
        <span class="cdk-visually-hidden">{{ num }}</span>

        <div class="sidebar-label" *ngIf="showLabel">{{ name }}</div>
    </button>
</div>