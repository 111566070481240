import { Injectable }                          from '@angular/core';
import { Observable }                          from 'rxjs';

import { MessageService }                      from '@Messaging/';
import { DataService }                         from '@Misc/Services/';

import {
    MobileCellCommon       as NobileCellC,
    MobileCellHelperCommon as MobileCellHelper
}                                              from '@Common/';



@Injectable({
    providedIn: 'root'
})
export class MobileCellsService extends DataService
{
    protected static readonly _title: string = "MobileCells";

    private          readonly _cells: any    = []; // array of known cells


    public constructor(MessageService: MessageService)
    { 
        super(MessageService, MobileCellsService._title, MessageService.messages.msgTypesInfo.mobilecells);

        this.refresh(); // default to quantityOnly this initially

        // // Listen for events
        // this.sub = this.MessageProcessingService.getObs$(Messages.events.mobilecells)
        //     .subscribe((d: any): void => {
        //         this.process(d, true);
        //     }); // subscribe
    }
    

    //
    // Interface
    //

    // Override    
    public get(id: string | number, type? : any): object | undefined
    {
        return this.getCell(id as string, "", "");
    }

    
    // Override
    protected override processMsgData(d: any[]): void
    {
        super.processMsgData(d);

        if (d && Array.isArray(d)) {
            console.log("Updating " + this.Title + " service with data: " + d.length);
            this.clear(false);
            // Object.entries(d).forEach(([k, v]): void => {
            Object.values(d).forEach((v): void => {
                this.process(v, false);
            }); // forEach
            this.updateObservables(false);
        }
        else {
            console.log("No data received to update " + this.Title + " service");
        }
    }


    //
    // Private methods
    //
    private process(s: any, update: boolean): void
    {
        // const s: MobileCellC = MobileCellC.get(d);
        if (s) {
            this.add(s.id, s, update);
            if (update) this.updateObservables();
        }
    }


    private addCell(cell: any): boolean
    {
        if (cell && cell.plmn && cell.gcid) {
            delete cell.latitude;
            delete cell.longitude;

            const i: number = this.findCell(cell.plmn, cell.gcid, cell.rat);
            if (i != null && i >= 0 && i < this._cells.length) {
                //console.log("Cell '" + cell.plmn + ", " + cell.gcid + "' already exists at pos " + i);
                this.updateCell(cell); // update any attributes
            }
            else {
                this._cells.push(cell);
                //console.log("Cell '" + cell.plmn + ", " + cell.gcid + ", " + cell.rat + "' not found; now added");
                return true;
            }
        }
        else {
            //console.log("Invalid plmn and/or gcid provided; cannot create new Cell object");
            //console.log(cell);
        }

        return false;
    }


    private deleteCell(plmn: string, gcid: string, rat: string): boolean
    {
        const i: number = this.findCell(plmn, gcid, rat);
        if (i != null && i >= 0 && i < this._cells.length) {
            this._cells.splice(i, 1);
            console.log("Cell '" + plmn + ", " + gcid + ", " + rat + "' found and deleted");
            return true;
        }
        else {
            console.log("Cell '" + plmn + ", " + gcid + ", " + rat + "' not found; cannot delete");
        }

        return false;
    }


    private getCell(plmn: string, gcid: string, rat: string): object | undefined
    {
        const i: number = this.findCell(plmn, gcid, rat);
        if (i != null && i >= 0 && i < this._cells.length) {
            return this._cells[i];
        }
        else {
            console.log("Cell '" + plmn + ", " + gcid + ", " + rat + "' not found");
        }

        return undefined;
    }


    // [TBD]
    // private process(d: any, update: boolean): void
    // {

    // }


    private updateCell(cell: any): boolean
    {
    //console.log(cell);
        delete cell.latitude;
        delete cell.longitude;
        const i: number = this.findCell(cell.plmn, cell.gcid, cell.rat);
        if (i != null && i >= 0 && i < this._cells.length) {
            this._cells[i] = {...this._cells[i], ...cell}; // spread op, replaces Object.assign()
            //angular.extend(this.cells[i], cell);
            //console.log("Cell '" + cell.plmn + ", " + cell.gcid + "' found and updated");
            return true;
        }
        else {
            console.log("Cell '" + cell.plmn + ", " + cell.gcid + ", " + cell.rat + "' not found; cannot update");
        }

        return false;
    }


    private findCell(plmn: string, gcid: string, rat: string): number
    {
        for (let i: number = 0, len = this._cells.length; i < len; i++) {
            if (this._cells[i]
                && this._cells[i].plmn == plmn
                && this._cells[i].gcid == gcid
                && this._cells[i].rat  == rat) {
                return i;
            }
        }

        return -1;
    }
}