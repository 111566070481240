import {
    Component,
    // ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
}                                   from '@angular/core';

import { BaseWithDynamicComponent } from '@Base/';

import { ElementHelper }            from './element-helper.class';
import { ElementInfoComponent }     from './element/';


const _dataAttr = "data";


@Component({
    selector:     'qp-element-info-container',
    templateUrl:  'element-info-container.component.html',
    styleUrls:   ['element-info-container.component.css'],
})
export class ElementInfoContainerComponent extends BaseWithDynamicComponent
{
    @ViewChild('container', { static: true, read: ViewContainerRef })
    container: ViewContainerRef;

    private _showType: boolean = false;


    @Output()
    public selected: EventEmitter<any> = new EventEmitter();


    // Getter and setters
    @Input()
    public get showType(): boolean
    {
        return this._showType;

    }

    public set showType(d: boolean)
    {
        this._showType = d;
        if (this.compRef instanceof ComponentRef) this.compRef.instance.showType = this._showType;
    }


    // public constructor(ComponentFactoryResolver: ComponentFactoryResolver)
    public constructor()
    {
        // super(ComponentFactoryResolver);
        super();
    }


    // Dynamic compnents doen't support two-way data binding, so update
    // when @Input change detected

    // Override
    public override ngOnChanges(changes: SimpleChanges): void
    {
        const ch: SimpleChange | undefined = changes ? changes[_dataAttr] : undefined;
        if (ch instanceof SimpleChange) this.setData(ch.currentValue);

        // class SimpleChange {
        //     constructor(previousValue: any, currentValue: any, firstChange: boolean)
        //     previousValue: any
        //     currentValue: any
        //     firstChange: boolean
        //     isFirstChange(): boolean
        //   }
    }


    //
    // Getters and setters
    //
    @Input()
    public set data(d: any | undefined) // [_dataAttr]
    {
        if (d) this.open(d);
        else   this.close();
    }


    //
    // Protected methods
    //

    // Override
    protected override close(): void
    {
        super.close();

        if (this.container && this.container.clear instanceof Function) this.container.clear();
    }


    //
    // Private methods
    //
    private open(d: any | undefined): void
    {
        // Get appropriate dynamic component type
        const t: typeof ElementInfoComponent | undefined = ElementHelper.getInfoType(d);
        if (t) {
            const first: boolean = !! (! this.compRef);
            super.openT(this.container, t, d);

            if (this.compRef instanceof ComponentRef && first) {
                this.sub = this.compRef.instance?.selected.subscribe((d2: any) => {
                    this.selected.emit(d2);
                }); // subscribe()
            }
        }
    }
}