import { CommonModule }           from '@angular/common';
import { FlexLayoutModule }       from '@angular/flex-layout';
import { NgModule }               from '@angular/core';

// Used for vertical tabs; Angular Material does not currently support vertical tabs
// import { NgbModule }              from '@ng-bootstrap/ng-bootstrap';
import { RouterModule }           from '@angular/router';

import { MaterialCustomModule }   from '@Material/';

import { TabsComponent }          from './tabs.component';
import { TabsContainerComponent } from './tabs-container.component';

import { tabsRoutes }             from './tabs.routes';

import { ElementsModule }         from '../Elements/';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule.forChild(tabsRoutes), // needed for lazy loading

        MaterialCustomModule,
        ElementsModule
    ],
    
    declarations: [
        TabsComponent,
        TabsContainerComponent,
    ],

    providers: [
    ],

    exports: [
        TabsComponent
    ]
})
export class TabsModule { }