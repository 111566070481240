export { Dateq } from '@Common/'; // get from common area


//import { Dateq as DateQc } from '@Common/';


// // Extend to be able to easily control the format that is used for display
// export class Dateq extends DateQc
// {
//     // //
//     // // Getters
//     // //
//     // public get displayStr(): string
//     // {
//     //     return this.toLocaleString();

//     // }


//     // //
//     // // Static methods
//     // //

//     // // Note will return current datetime if null arg
//     // public static get(d?: number | string | Date): Dateq
//     // {
//     //     // Check if TZ info present; if not, add 'Z' for Zulu/UTC as all incoming dates should be UTC
//     //     if (typeof d === "string" && ! d.toUpperCase().endsWith('Z')) d+= 'Z';

//     //     try {
//     //         // If arg is string, will add Z for Zulu/UTC as all incoming dates should be UTC
//     //         return d ? new Dateq(d) : new Dateq();
//     //     }
//     //     catch(e) {
//     //         console.log("Exception creating dateq object");
//     //         console.log(e);
//     //     }

//     //     return null;
//     // }
// }