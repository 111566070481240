import { Injectable }     from '@angular/core';
import {
    BehaviorSubject,
    Observable
}                         from 'rxjs';

import { BaseService }    from '@Base/';
import { MessageService } from '@Messaging/';


@Injectable({
    providedIn: 'root'
})
export class NotificationsService extends BaseService
{
    public readonly _notifications: any[] = [];

    private _numSubject: BehaviorSubject<number>;


    constructor(private readonly MessageService: MessageService)
    {
        super();
     
        this._numSubject = new BehaviorSubject<number>(this._notifications.length);

        this.refresh(); // can't be called from 
    }


    // Getters
    public get get(): any[]
    {
        return this._notifications;
    }


    public get updatedNum(): Observable<number>
    {
        return this._numSubject.asObservable();
    }


    //
    // Public functions
    //
    public getNumNotifications(level?: number): number
    {
        // [TBD]
        if      (1 == level) {
            return 10;
        }
        else if (2 == level) {
            return 23;
        }
        else {
            return this.get.length;
        }
    }


    public refresh(cleared?: boolean): any
    {
        console.debug("Refreshing Notifications service");

        // [TB] commented out as race conditions means it can trigger message send with no user token, which
        // in turn triggers server to send logout message back
        // Need to have MessageService receive user details before sending any messages to server
        // return this.MessageService.sendMsg(this.MessageService.messages.msgTypes.getdata,
        //                                   {type: this.MessageService.messages.msgTypesSub.notifications, cleared: cleared});
    }
    

    public update(data: any): boolean
    {
        console.log(data);

        if (data) {
            console.debug("Updating Notifications service with data: " + data.length);

            this._notifications.length = 0; // clear all existing devices; don't use 'devices = []' as devices is const, and can break if other refs to devices
            for (let i of data) {
                this._notifications.push(i);
            } // for
            this.updateObservables();
            return true;
        }
        else {
            console.warn("No data received to update Notifications service");
        }

        return false;
    }


    //
    // Private functions
    //
    protected override initialise(): void
    {
        console.debug("Initialising Notifications service");
    }


    private updateObservables(): void
    {
        this._numSubject.next(this._notifications.length);
    }
}