<div (click)="onClick($event)"
    matTooltip="{{ 'Wifi Access Points' + (showBadge && this.num ? ': ' + this.num : '') }}">
    
    <button mat-button [color]="active ? buttonActive : ''">
        <mat-icon [matBadge]="num" [matBadgeColor]="badgeColour" [matBadgePosition]="badgePosition" [matBadgeSize]="badgeSize" [matBadgeHidden]="! showBadge || num <= 0" aria-label="Wifi APs view">
            {{ icon }}
        </mat-icon>
        <span class="cdk-visually-hidden">{{ num }}</span>

        <div class="sidebar-label" *ngIf="showLabel">Wifi APs</div>
    </button>
</div>