import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';

import { MobileCellGsmCommon }  from './';


@Component({
    selector:     'qp-mobile-cell-gsm-info',
    templateUrl:  'mobile-cell-gsm-info.component.html',
    styleUrls:   ['mobile-cell-gsm-info.component.css'],
})
export class MobileCellGsmInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:       MobileCellGsmCommon;

    @Input()
    public nullValues: boolean              = true;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }

    // get d(): MobileCellGsmCommon
    // {
    //     return this.cell as MobileCellGsmCommon;
    // }
}