<div class="div" *ngIf="d && d.type && d.type != 'none'">
    <mat-card *ngIf="matCard">

        <mat-card-subtitle *ngIf="matCard">Connection</mat-card-subtitle>
        <h4 *ngIf="! matCard" class="mat-subheading-1">Connection</h4>

        <mat-card-content *ngIf="matCard">
            <qp-table-data
                [nullValues]=false [data]="
                    [
                        { k: 'Type', v: d.type },
                    ]">
            </qp-table-data>

        </mat-card-content>
    </mat-card>
</div>