<div [ngClass.gt-sm]="'all'">

<!-- </div>class="all"> -->

    <!-- headerPosition="below" -->


    <!-- <mat-tab-group -->

    <nav mat-tab-nav-bar
        mat-stretch-tabs="true"
        dynamicHeight="true"
        animationDuration="500ms"
        preserveContent="false"
        [tabPanel]="tabPanel"
        aria-label="Tabs"

        class="icons tabbar"
        [ngClass]="'vertical'"
        [ngClass.lt-md]="'horizontal'">

        <a mat-tab-link
            [routerLink]="'/dashboard'"
            [active]="activatedRoute.startsWith('/dashboard')"
        >
            <qp-sidebar-dashboard class="dashboard-icon-style"></qp-sidebar-dashboard>
        </a>


        <a mat-tab-link
            [routerLink]="'/organisations'"
            [active]="activatedRoute.startsWith('/organisations')"
        >
            <qp-sidebar-organisations class="dashboard-icon-style"></qp-sidebar-organisations>
        </a>


        <a mat-tab-link
            [routerLink]="'/surveys'"
            [active]="activatedRoute.startsWith('/surveys')"
        >
            <qp-sidebar-surveys class="dashboard-icon-style"></qp-sidebar-surveys>
        </a>


        <a mat-tab-link
            [routerLink]="'/devices'"
            [active]="activatedRoute.startsWith('/devices')"
        >
            <qp-sidebar-devices class="dashboard-icon-style"></qp-sidebar-devices>
        </a>


        <a mat-tab-link
            [routerLink]="'/map'"
            [active]="activatedRoute.startsWith('/map')"
            >
            <qp-sidebar-map class="dashboard-icon-style"></qp-sidebar-map>
        </a>


        <a mat-tab-link
            [routerLink]="'/mobilecells'"
            [active]="activatedRoute.startsWith('/mobilecells')"
            >
            <qp-sidebar-mobilecells class="dashboard-icon-style"></qp-sidebar-mobilecells>
        </a>


        <a mat-tab-link
            [routerLink]="'/wifiaps'"
            [active]="activatedRoute.startsWith('/wifiaps')"
            >
            <qp-sidebar-wifiaps class="dashboard-icon-style"></qp-sidebar-wifiaps>
        </a>

    </nav>

    
    <div class="mat-sidenav-content">
        <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
        </mat-tab-nav-panel>
    </div>


<!----
    <mat-tab-group
        mat-stretch-tabs="true"
        dynamicHeight="true"
        animationDuration="500ms"
        preserveContent="false"
    
       class="icons"
       [ngClass]="'vertical'"
       [ngClass.lt-md]="'horizontal'">
        <mat-tab><ng-template mat-tab-label>
            <qp-sidebar-dashboard     class="dashboard-icon" [active]="activatedRoute.startsWith('/dashboard')"     routerLink="/dashboard"></qp-sidebar-dashboard>
        </ng-template></mat-tab>


        <mat-tab routerLink="/organisations"><ng-template mat-tab-label>
            <qp-sidebar-organisations class="dashboard-icon" [active]="activatedRoute.startsWith('/organisations')" routerLink="/organisations"></qp-sidebar-organisations>
        </ng-template></mat-tab>


        <mat-tab><ng-template mat-tab-label>
            <qp-sidebar-surveys       class="dashboard-icon" [active]="activatedRoute.startsWith('/surveys')"       routerLink="/surveys"></qp-sidebar-surveys>
        </ng-template></mat-tab>


        <mat-tab><ng-template mat-tab-label>
            <qp-sidebar-map           class="dashboard-icon" [active]="activatedRoute.startsWith('/map')"           routerLink="/map"></qp-sidebar-map>
        </ng-template></mat-tab>


        <mat-tab><ng-template mat-tab-label>
            <qp-sidebar-mobilecells   class="dashboard-icon" [active]="activatedRoute.startsWith('/mobilecells')"   routerLink="/mobilecells"></qp-sidebar-mobilecells>
        </ng-template></mat-tab>


        <mat-tab><ng-template mat-tab-label>
            <qp-sidebar-wifiaps       class="dashboard-icon" [active]="activatedRoute.startsWith('/wifiaps')"       routerLink="/wifiaps"></qp-sidebar-wifiaps>
        </ng-template></mat-tab>

    </mat-tab-group>

    <div class="mat-sidenav-content">
        <router-outlet></router-outlet>
    </div>
-->
</div>


<!-- <ngb-navset type="pills" [orientation]="currentOrientation" (tabChange)="tabChanged($event)" class="tab-animation"> -->
    
    <!-- <ngbNav id="dashboard">
        <ng-template ngbTabTitle>
            <button mat-button color="primary" matTooltip="Dashboard">
                <mat-icon aria-label="Dashboard view">dashboard</mat-icon>
            </button>
        </ng-template>

        <ng-template ngbTabContent>
            <qp-dashboard></qp-dashboard>
        </ng-template>
    </ngbNav> -->


    <!-- <ngbNav id="organisations">
        <ng-template ngbTabTitle>
            <button mat-button color="primary" matTooltip="Organisations (Quantity: {{ organisationsNum }})"> -->
                <!-- <mat-icon aria-label="Organisations view">business</mat-icon> -->
                <!-- <mat-icon aria-label="Organisations view">location_city</mat-icon>
            </button>
        </ng-template>

        <ng-template ngbTabContent>
            <qp-organisations-container></qp-organisations-container>
        </ng-template>
    </ngbNav> -->


    <!-- <ngbNav id="map">
        <ng-template ngbTabTitle>
            <button mat-button color="primary" matTooltip="Map">
                <mat-icon aria-label="Map view">map</mat-icon>
            </button>
        </ng-template>

        <ng-template ngbTabContent>
            <qp-map></qp-map>
        </ng-template>
    </ngbNav> -->


    <!-- <ngbNav id="mobilecells" *ngIf="gotPermission()">
        <ng-template ngbTabTitle>
            <button mat-button color="primary" matTooltip="Mobile Cells (Quantity: {{ mobileCellsNum }})">
                <mat-icon aria-label="Mobile cells view">signal_cellular_alt</mat-icon> -->
                <!-- <mat-icon aria-label="Mobile cells view">signal_cellular_4_bar</mat-icon> -->
                <!-- <mat-icon aria-label="Mobile cells view">network_cell</mat-icon> -->
            <!-- </button>
        </ng-template>

        <ng-template ngbTabContent>
            <qp-mobile-cells></qp-mobile-cells>
        </ng-template>
    </ngbNav> -->


    <!-- <ngbNav id="wifi" *ngIf="gotPermission()">
        <ng-template ngbTabTitle>
            <button mat-button color="primary" matTooltip="Wifi Access Points (Quantity: {{ wifiApsNum }})">
                <mat-icon aria-label="Wifi APs view">wifi</mat-icon>
            </button>
        </ng-template>

        <ng-template ngbTabContent>
            <qp-wifi></qp-wifi>
        </ng-template>
    </ngbNav> -->

<!-- </ngb-navset> -->