import { Socket as ioSocket } from 'socket.io-client';

import { SocketIoBase }       from '@Common/';
export { SocketIoEvent }      from '@Common/';


export class SocketIo extends SocketIoBase
{
    constructor(...args: any[])
    {
        super(ioSocket, ...args);
    }


    public static override get(...args: any[])
    {
        return new SocketIo(...args);
    }
}