/*
    // DEPRECATED //
*/


import {
    BehaviorSubject,
    Observable
}                                             from 'rxjs';

import { Icons }                              from '@Icons/';

import { Element }                            from '../element/element/element.class';
import { Site }                               from '../site/';

import { Organisation as OrganisationCommon } from './';


export class Organisation extends Element
{
    protected static override readonly _tag:         string                         = "organisation"; // used in URLs

    // private                            _name:        string                         = "";
    private readonly                   _siteList:    Site[]                         = [];
    private                            _siteDefault: Site | undefined;

    // Override
    protected                          _update$:     BehaviorSubject<Organisation>;
    

    // Need to have server send address and geolocation of org based on
    // new DB field to mark which site is default, or default to first site
    // returned for that org.  Can't do on client as org query doesn't
    // include site info


    constructor(d?: any) // don't set arg to private - TS Parameter Property
    {
        super(d); // [TBD] don't pass to Element parent as ID is a number here but parent expects a string

        if (d) {
            this.elementC          = OrganisationCommon.get(d);
            // this.id           = d.id           ? d.id
            //     : (d._id      ? d._id          : this.id);

            // this._name        = d.name         ? d.name
            //     : (d._name    ? d._name        : this._name);
            // this.description = this._name;

            // this.setGeolocation(MapElementLatLng.get(
            //     this.getAttr(d, 'latitude'),
            //     this.getAttr(d, 'longitude'),
            //     this.getAttr(d, 'noWrap'),
            //     this.getAttr(d, 'accuracy'),
            //     this.getAttr(d, 'speed'),
            // ));

            const sites: any[] = 
                d.sites
                    ? d.sites
                    : (d._sites ? d._sites : []);

            sites.forEach((s: any): void => {
                const s2: Site | undefined = Site.get(s);
                if (s2 instanceof Site) {
                    let i: number = this._siteList.push(s2);
                    // Find default site for organisation
                    if (s && s.isDefault) this._siteDefault = this._siteList[i - 1];
                }
            }); // forEach

            if (! this._siteList.length) {
                const sitesTotal: number = d.sitesTotal ? d.sitesTotal
                        : (d._sitesTotal ? d._sitesTotal : 0);
                for (let i: number = 0; i < sitesTotal; ++i) {
                    const s2: Site | undefined = Site.get();
                    if (s2 instanceof Site) this._siteList.push(s2);
                } // for
            }

            // Set organisation location to that of default site, to allow map plotting
    // if (this.siteDefault) this.geolocation = this.siteDefault.geolocation;

            // this._sitesTotal = this._sites.length ? this._sites.length
            //     : (d.sitesTotal ? d.sitesTotal
            //         : (d._sitesTotal ? d._sitesTotal : this._sitesTotal));
            console.log(this);
        }

        this._update$ = new BehaviorSubject<Organisation>(this);
        this.notify(this);
    }


    //
    // Static methods
    //
    public static get(d?: any): Organisation
    {
        return new Organisation(d);
    }


    //
    // Getters and setters
    //
    public get devices(): number
    {
        let i: number = 0;
        this.siteList.forEach((d: Site): void => {
            if (d) i += d.devices;
        }); // forEach

        return i;
    }


    public get address(): string
    {
        return this.siteDefault ? this.siteDefault.address : "";
    }


    // Override
    public override get markerTitle(): string
    {
        return this.tag + ": " + this.name;
    }


    public get name(): string
    {
        return this.elementC ? this.elementC.name : "";
    }


    // Override
    public get notification(): Observable<Organisation>
    {
        return this._update$.asObservable();
    }


    public get siteDefault(): Site | undefined
    {
        return this._siteDefault ? this._siteDefault : (this.siteList[0] ? this.siteList[0] : undefined);
    }


    public get siteList(): Site[]
    {
        return this._siteList;
    }


    public get sites(): number
    {
        return this.elementC && this.elementC instanceof OrganisationCommon
            ? (this.elementC as OrganisationCommon).sitesQuantity
            : 0;
        // return this.siteList.length;
    }


    // Override
    public override get tag(): string
    {
        return Organisation.tag;
    }

    
    // Override
    public get update$(): BehaviorSubject<any>
    {
        return this._update$;
    }


    
    //
    // Protected methods
    //

    // Override
    protected getIcon(cb: (d: any) => void): void 
    {
        if (cb instanceof Function) cb(Icons.organisation);
    }


    // Override
    protected override notify(d: any): boolean
    {
        return super.notify(d, false);
    }
}