import { Injectable }         from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subscription,

    filter,
    take
}                             from 'rxjs';

import { BaseService }        from '@Base/';
import {
    MessageProcessingService,
    MessageService
}                             from '@Messaging/';

import { MobileNetwork }      from './mobile-network.class';


@Injectable({
    providedIn: 'root'
})
export class MobileNetworksService extends BaseService
{
    private dataAvailableSubject: BehaviorSubject<boolean>;
    private networks:             any                       = {}; // associative array of known mobile networks


    constructor(private readonly MessageService:           MessageService,
                private readonly MessageProcessingService: MessageProcessingService)
    {
        super();

        this.dataAvailableSubject = new BehaviorSubject<boolean>(false);

        // Listen for events
        const obs: Observable<any> | undefined = this.MessageProcessingService.getObs$(MessageService.messages.msgTypesInfo.networklist);
        if (obs instanceof Observable) obs
            .subscribe((d: any): void => {
                if (d) {
                    console.debug("Networks received");
                    console.debug(d);
                    // [TBD]
                }
            });

        this.refresh();
    }


    // Getters
    public get dataAvailable(): Observable<boolean> | undefined
    {
        return this.dataAvailableSubject ? this.dataAvailableSubject.asObservable() : undefined;
    }


    //
    // Public methods
    //
    public getIcon(plmn?: string, name?: string, cb?: Function): any//Observable<object> // 'id' used to 'store' observable id
    {
        // Function is run when new Observable is subscribed to
        //return new Observable((sub: Subscriber<object>): void => {
            const obs: Observable<boolean> | undefined = this.dataAvailable;
            if (obs instanceof Observable) {
                this.sub = obs
                    .pipe(filter((available: boolean): boolean => available == true)) // only emit when true
                    .pipe(take(1))                                                    // auto-unsubscribe after response
                    .subscribe((available: boolean):   void => {
                        if (cb instanceof Function) cb(this.getIconS(plmn, name));
                        //sub.next(available ? this.getIconNow(plmn, name) : undefined);
                        //sub.complete();
                    }); // subscribe()
            }
            else {
                if (cb instanceof Function) cb(undefined);
            }
        //}); // new Observable
    }


    // public get(obj: any): MobileNetwork
    // {
    //     return (new MobileNetwork(obj));
    // }


    public update(data: any): void
    {
        if (data && data.length) {
            console.debug("Updating networks list");
            console.debug(data);
            for (let d of data) {
                const network: any = MobileNetwork.get(d);
                if (network && network.plmn) {
                    if (! this.networks[network.plmn]) this.networks[network.plmn] = {};
                    this.networks[network.plmn][network.name] = network;
                }
            } // for
        }
    }


    //
    // Protected methods
    //
    protected override initialise(): void
    {
        console.debug("Initialising Networks service");
    }


    protected override cleanUp(): void
    {
        super.cleanUp();

        this.dataAvailableSubject.complete();
    }


    //
    // Private methods
    //
    private getIconS(plmn?: string, name?: string): object | undefined
    {
        const nw: MobileNetwork = this.getNetwork(plmn, name);
        return nw ? nw.icon : undefined;
    }


    private getNetwork(plmn: string | undefined, name: string | undefined): any 
    {
        if      (plmn) {
            // If plmn available
            if (this.networks[plmn]) return this.networks[plmn][name ? name : plmn]
        }
        else if (name) {
            console.debug("x1: " + name);
            console.debug(this.networks);
            // If only network name, then search for first match
            return Object.values(this.networks).find((d: any): boolean => {
                console.debug(d);
                console.debug(d[name])
                return d && d[name];
            });
        }

        return undefined;
    }

  
    private refresh(): Subscription | undefined
    {
        console.debug("Refreshing Networks service - getting all networks");

        // Use observable
        const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
            this.MessageService.messages.msgTypesInfo.networklist
        );

        return obs instanceof Observable
            ? this.sub = obs
                .pipe(take(1))
                .subscribe((data: any): void => {
                    this.update(data ? data.data : data);
                    this.dataAvailableSubject.next(true);
            }) // subscribe
            
            : undefined;
    }
}