<div (click)="onClick($event)"
    matTooltip="{{ 'Surveys' + (showBadge && this.num ? ': ' + this.num : '') }}">

    <button mat-button [color]="active ? buttonActive : ''">
        <mat-icon [ngClass]="showLabel ? '' : 'noLabel'" [matBadge]="num" [matBadgeColor]="badgeColour" [matBadgePosition]="badgePosition" [matBadgeSize]="badgeSize" [matBadgeHidden]="! showBadge || num <= 0" aria-label="Surveys view">
            {{ icon }}
        </mat-icon>
        <span class="cdk-visually-hidden">{{ num }}</span>

        @if (showLabel) {
            <div class="sidebar-label">Surveys</div>
        }

        @if (showExpansion) {
            <span>
                <mat-icon class="expansion-icon">chevron_right</mat-icon>
            </span>
        }
    </button>
</div>