import { NgModule }       from '@angular/core';

import { SurveysService } from './surveys.service';
 

@NgModule({
    imports: [
    ],
    
    declarations: [
    ],

    providers: [
        SurveysService
    ],

    exports: [
    ]
})
export class SurveysServiceModule { }