<qp-table-data *ngIf="d"
    [nullValues]="nullValues"
    [data]="
        [
            { k: 'Pci',           v: d.pci                               },
            { k: 'Tac',           v: d.tac                               },
            { k: 'Rsrp Ss',       v: d.rsrpss  ? d.rsrpss  + ' dBm' : '' },
            { k: 'Rsrq Ss',       v: d.rsrqss  ? d.rsrqss  + ' dB' : ''  },
            { k: 'Sinr Ss',       v: d.sinrss  ? d.sinrss  + ' dB' : ''  },
            { k: 'Rsrp Csi',      v: d.rsrpcsi ? d.rsrpcsi + ' dBm' : '' },
            { k: 'Rsrq Csi',      v: d.rsrqcsi ? d.rsrqcsi + ' dB' : ''  },
            { k: 'Sinr Csi',      v: d.sinrcsi ? d.sinrcsi + ' dB' : ''  },
            { k: 'Asu',           v: d.asu                               },
            { k: 'Cqi',           v: d.cqi                               },
            { k: 'Ta',            v: d.ta                                }
        ]">
</qp-table-data>
<!-- { k: 'Local cell Id', v: d.localcellid                       }, -->