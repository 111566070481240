import {
    Component,
    Input
}                               from '@angular/core';

import {
    MobileCell,
    MobileCellLevelComponent
}                               from '../mobile-cell/';

import { MobileCellCdmaCommon } from './';


@Component({
    selector:     'qp-mobile-cell-cdma-level',
    templateUrl:  'mobile-cell-cdma-level.component.html',
    styleUrls:   ['mobile-cell-cdma-level.component.css'],
})
export class MobileCellCdmaLevelComponent extends MobileCellLevelComponent
{
    //
    // Getters
    //

    public static override getLevelToolTip(d: MobileCell): string
    {
        return super.getLevelToolTip(d);
            // + (d instanceof MobileCellCdmaCommon
            //     ? (d.rsrp ? "\nRsrp: " + d.rsrp : "")
            //         + (d.rsrq ? "\nRsrq: " + d.rsrq  : "")
                
            //     : ""
            // );
    }
}