<div class="div" *ngIf="d">

    <p></p>

    <h4 class="mat-subheading-1">Wifi</h4>
    <mat-list dense>
        <qp-data-element-item *ngIf="d.ssid"              [light]="light" [key]="'Ssid'"  [text]="d.ssid"> </qp-data-element-item>
        <qp-data-element-item *ngIf="d.bssid && ! d.ssid" [light]="light" [key]="'Bssid'" [text]="d.bssid"></qp-data-element-item>
        <qp-data-element-item *ngIf="d.rssi > d.rssiMin"  [light]="light" [key]="'Rssi'"  [text]="'' + d.rssi"> </qp-data-element-item>
    </mat-list>

</div>