<qp-table-data *ngIf="d"
    [nullValues]="nullValues"
    [data]="
        [
            { k: 'Enobeb Id',     v: d.ecid                         },
            { k: 'Local cell Id', v: d.localcellid                  },
            { k: 'Pci',           v: d.pci                          },
            { k: 'Tac',           v: d.tac                          },
            { k: 'Rsrp',          v: d.rsrp ? d.rsrp + ' dBm' : ''  },
            { k: 'Rsrq',          v: d.rsrq ? d.rsrq + ' dB' : ''   },
            { k: 'Rssnr',         v: d.rssnr ? d.rssnr + ' dB' : '' },
            { k: 'Asu',           v: d.asu                          },
            { k: 'Cqi',           v: d.cqi                          },
            { k: 'Ta',            v: d.ta                           }
        ]">
</qp-table-data>