<div class="div" *ngIf="d">

    <qp-element-info [data]="data" [light]="light" [showType]=showType [showUpdated]=true></qp-element-info>

    <!-- <p></p>
    
    <h4 class="mat-subheading-1">{{ data.tag | titlecase }}</h4> -->
    <!-- <mat-list dense> -->

    <!-- <qp-table-data *ngIf="d" [nullValues]=false [data]="
        [
            { k: 'Name',            v: d.name        | titlecase },
            { k: 'Description',     v: d.description | titlecase },
            { k: 'Type',            v: d.type        | titlecase },
            { k: 'Device',          v: d.deviceId                },
            { k: 'Id',              v: d.surveyId                },
            d.start ? { k: 'Start', v: d.start + ''              } : {},
            d.end   ? { k: 'End',   v: d.end + ''                } : {},
            { k: 'Duration (secs)', v: d.duration                }
        ]">
    </qp-table-data> -->

    <!-- <mat-list dense>
        <qp-data-element-item [light]="light" [key]="'Name'"            [text]="d.name        | titlecase"></qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Description'"     [text]="d.description | titlecase"></qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Start'"           [text]="d.start + ''">             </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'End'"             [text]="d.end + ''">               </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Duration (secs)'" [text]="'' + d.duration">          </qp-data-element-item>
    </mat-list> -->

</div>