import { Injectable }        from '@angular/core';
import { Observable }        from 'rxjs';

import { Survey }            from '@ObjElements/';

import {
    Messages,
    MessageService,
    MessageProcessingService 
}                            from '@Messaging/';
import {
    DataService,
    // DataServiceEvents
}                            from '@Misc/Services/';


@Injectable({
    providedIn: 'root'
})
export class SurveysService extends DataService
{
    protected static readonly _title: string = "Surveys";


    public constructor(private readonly MessageProcessingService: MessageProcessingService,
                                        MessageService:           MessageService)
    {
        super(
            MessageService,
            SurveysService._title,
            MessageService.messages.msgTypesInfo.surveys
        ); // super()

        // Don't call from within initialise() as that is called by parent constructor and required this.MessageService not available then
        this.refresh(); // default this to quantityOnly initially
        // this.refresh(true);

        // Listen for survey events
        const obs: Observable<any> | undefined = this.MessageProcessingService.getObs$(Messages.events.survey);
        if (obs instanceof Observable) this.sub = obs
            .subscribe((d: any): void => {
                // this.process(d, false);
                if (this.process(d, false)) this.sizeI = this.size + 1;
                this.updateObservables(false);
            }); // subscribe
    }

    
    //
    // Interface
    //

    // Override
    public override get(id: string | number): object
    {
        return this.getEl(id as string);
    }


    // Override
    protected override processMsgData(d: any[]): void
    {
        super.processMsgData(d);

        if (Array.isArray(d)) {
            console.debug(d);

            console.debug("Updating " + this.Title + " service with data: " + d.length);
            this.clear(false);
            Object.values(d).forEach((v): void => {
                this.process(v, false);
            }); // forEach
            this.updateObservables(false);
        }
        else {
            console.warn("No data received to update " + this.Title + " service");
        }
    }


    //
    // Private methods
    //
    private getEl(id: string): Survey
    {
        return this.getElement(id) as Survey;
    }


    private process(d: any, update: boolean = true): boolean
    {
        const s: Survey | undefined = Survey.get(d);
        if (s instanceof Survey) {
            // Check if already exists; update if so
            const ret: boolean = !! this.add(s.id, s, update);
            if (! ret && !! this.update(s)) this.elementUpdated$?.next(s.id);

            return ret;
        }

        return false;
    }


    private update(d: Survey): Survey | undefined
    {
        // const s: Survey | undefined = (d instanceof Survey) ? this.getEl(d.id) : undefined;
        return ((d instanceof Survey) ? this.getEl(d.id) : undefined)?._merge(d);
    }
}