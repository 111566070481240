import { IconsService }      from '@Icons/';
import {
    Element,
    ElementState,
    ElementStatus
}                            from '../../element/element/';
import {
    Notification, // <-- this is NotificationCommon
    NotificationHelper,
}                            from '../../notification/';

// import { DeviceWithBattery } from '../device-with-battery/'; // NOT from Wifi module
import { DeviceWithWifi }    from '../device-with-wifi/';    // NOT from Wifi module
//import { DeviceI } from './device.interface';

import {
    ConnectionCommon,
    DeviceHelper
}                            from '@Common/Elements/Devices/';

import {
    DeviceActions,
    DeviceCommon,
    DeviceTypes
}                            from './';


export abstract class Device extends Element
{
    protected static override readonly _tag:   string                       = "device"; // used in URLs

    private readonly          _notifications:  Record<string, Notification> = {};
    private                   _notificationsV: Notification[]               = [];

    private                   _deviceSubType:  string                       = "";

    private                   _pendingDelete:  boolean                      = false;
    private                   _powerReset:     boolean                      = false;
    private                   _wifi:           DeviceWithWifi;

    public                    connectionType:  string                       = "";
    public                    deviceId:        number                       = 0;


    protected constructor(d?: any, iconsService?: IconsService)
    { 
        super(d, iconsService, DeviceHelper.field);
    }


    //
    // Getters and setters
    //    
    public get action(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).action
            : "";
    }


    public get appSoftwareVersion(): string
    {
        return this.elementC && this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).appSoftwareVersion
            : "";
    }


    public get capabilityPowerControl(): boolean
    {
        return this.powerStatus != undefined;
    }


    public get connectedDate(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).connectedDate
            : "";
    }


    public get connection(): ConnectionCommon
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).connection
            : "";
    }


    public get deviceName(): string
    {
        return this.manufacturer + " " + this.model + " (" + this.load + ")";
    }


    public get deviceType(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).deviceType
            : "";
    }


    public get load(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).load
            : "";
    }


    public get manufacturer(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).manufacturer
            : "";
    }


    // Override
    public override get markerTitle(): string
    {
        return this.tag + ": " + super.markerTitle;
    }

    
    public get model(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).model
            : "";
    }


    public get modelName(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).modelName
            : "";
    }


    public get notifications(): Record<string, Notification>
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).notifications
            : undefined;
    }

    public get notificationsV(): Notification[]
    {
        return this.notifications ? Object.values(this.notifications) : [];
    }
    
    
    protected set notificationO(d: object | Notification | undefined)
    {
        const n: Notification | undefined  = d ? NotificationHelper.get(d, this.IconsService) : undefined;
        if (n) {
            // if (Notification.isSet(n.status)) this._notifications[n.id] = n;
            // else                              delete this._notifications[n.id];
            this.updateNotificationsList();
        }
    }

    // Setter configured like this protect setting
    protected set notificationsI(d: object)
    {
        if (d) {
            Object.entries(d).forEach(([k, v]): void => {
                this.notificationO = v;
                // Don't call updateNotificationsList(), the setter above will call it
            }); // forEach
        }
        else {
            // Clear map
            Object.keys(this.notifications).forEach((e: string): void => {
                if (e) delete this.notifications[e];
            }); // forEach
            this.updateNotificationsList();
        }
    }


    public get pendingDelete(): boolean
    {
        return this._pendingDelete;
    }

    public set pendingDelete(d: boolean)
    {
        if (typeof d !== 'undefined') {
            this._pendingDelete = d;
            if (this._pendingDelete) {
                console.log("Device '" + this.id + "' set to pendingDelete");
                // this.statusI = ElementStatus.Offline;
            }
            this.notify(d);
        }
    }


    public get powerReset(): boolean
    {
        return this._powerReset;
    }

    private set powerResetI(d: boolean)
    {
        this._powerReset = d;
    }


    public get powerStatus(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).powerStatus
            : "";
    }


    public get reconnectedDate(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).reconnectedDate
            : "";
    }


    public get status(): string
    {
        return this.statusI instanceof ElementStatus
            ? this.statusI.status
            : "";
    }


    public get statusI(): ElementStatus
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).statusI
            : undefined;
    }


    // Override
    public override get tag(): string
    {
        return Device.tag;
    }
    

    public get type(): string
    {
        return this.elementC instanceof DeviceCommon
            ? (this.elementC as DeviceCommon).type
            : "";
    }


    public get wifi(): DeviceWithWifi | undefined
    {
        return this._wifi
    }

    public set wifi(d: DeviceWithWifi | undefined)
    {
        this.setAttr('_wifi', d);
    }


    //
    // Public methods
    //

    // Override
    // public override infoWinContent(): string
    // {
    //     let contentStr: string = super.infoWinContent();

    //     contentStr += "<br><br><b><b><i>" + Device.tag + "</i></b></b>";
    //     contentStr += 
    //           "<br><b>Manufacturer</b>: "          + this.manufacturer
    //         + "<br><b>Model</b>: "                 + this.model;

    //     if (this.softwareVersion) contentStr += "<br><b>Software</b>: " + this.deviceSubType + " " + this.softwareVersion;
            
    //     contentStr += "<br><b>Status</b>: " + this.status;
    //     if (this.statusI === ElementStatus.Online) {
    //         if (this.connectedDate)   contentStr += "<br><b>Connected</b>: "   + this.connectedDate;
    //         if (this.reconnectedDate) contentStr += "<br><b>Reconnected</b>: " + this.reconnectedDate;
    //     }


    //     // Type of connection to server
    //     if (this.connectionType) contentStr += "<br><b>Connection</b>: " + this.connectionType;

    //     // Wifi
    //     if (this.connectionType === "wifi" && this.wifi) contentStr += this.wifi.infoWinContent();

    //     return contentStr;
    // }


    // Override
    public override merge(d: any, doNotify: boolean = true): Element
    {
        console.debug(this.id + " - Merging new device update - Device: " + (d instanceof Device) + ", DeviceCommon: " + (d instanceof DeviceCommon));

        super.merge(d, doNotify);

        if (d) {
            //this.capabilityPowerControl = this.getAttr(d, 'capabilityPowerControl');
            // this.connectedDate          = this.getAttr(d, 'connectedDate');  // setter will create date object
// this.connectionType         = this.getAttr(d, 'connectionType');
// this.deviceId               = this.getAttr(d, 'deviceId');
//             // this.softwareVersion        = this.getAttr(d, 'deviceSoftwareLoad');
// this.deviceSubType          = this.getAttr(d, 'deviceType');
//             // this.manufacturer           = this.getAttr(d, 'manufacturer');
//             // this.model                  = this.getAttr(d, 'model');
// this.notificationsI         = this.getAttr(d, 'notifications');
// this.pendingDelete          = this.getAttr(d, 'pendingDelete');
// this.powerStatusI           = this.getAttr(d, 'powerStatus');
//             // this.reconnectedDate        = this.getAttr(d, 'reconnectedDate'); // setter will create date object
//     this.statusI                = this.getAttr(d, 'status');
// console.log(d.status);
// console.log(this.status);
            //if (data.type               != undefined) this.deviceType         = data.type;


            // Set powerReset flag, which is used by web GUI to flash the power icon and disable the options during a reset
            switch (this.powerStatus) {
                case DeviceCommon.powerState.reset:
                    this.powerResetI = true;
                    break;

                case DeviceCommon.powerState.on:
                    if (this.powerReset) this.powerResetI = false;
                    break;
            } // switch
      
            // // Set GUI status
            // if (this.statusI !== ElementStatus.Offline) {
            //     this.pendingDelete = false; // clear pendingDelete marker
            //     this.state = ElementState.Good;
            // }
            // else this.state = ElementState.Poor;


            
            // // Only add battery info if valid
            // if (this.battery) this.battery.merge(d)
            // else {
            //     const b: DeviceWithBattery = DeviceWithBattery.get(d);
            //     if (b && typeof b.batteryLevel !== 'undefined' && b.batteryLevel >= 0) this.battery = b;
            // }

            // this.sub = null; // [TBD] clear all subscriptions to ensure no duplicates
            // if (this.battery) this.sub = this.battery.notification.subscribe((d: DeviceWithBattery): void => {
            //     this.notify();
            // }); // subscribe

            if (this.wifi) this.wifi.merge(d)
            else           this.wifi = DeviceWithWifi.get(d);

            if (this.wifi) this.sub = this.wifi.notification.subscribe((d: DeviceWithWifi): void => {
                this.notify();
            }); // subscribe

            // Check for action
            if (this.elementC instanceof DeviceCommon) {
                (this.elementC as DeviceCommon).action = 
                    (typeof d.testResultType === "string" && d.testResultType.includes("start")) ? DeviceActions.test : undefined;
            }

            if (doNotify) this.notify();
        }

        return this;
    }


    public processNotification(d: object | Notification)
    {
        this.notificationO = d;
    }


    //
    // Protected methods
    //
        
    // Override
    protected getIcon(cb: (d: any) => void): any 
    {
        return (this.action)
            ? (this.IconsService && this.IconsService.getIconTest2 instanceof Function
                ? this.IconsService.getIconTest2().then((d2: any) => {
                    // Set origin?
                    if (cb instanceof Function) return cb(d2);
                }) // getIconTest2

                : (cb instanceof Function
                    ? cb(undefined)
                    : undefined
                )
            )

            : (cb instanceof Function
                ? cb(undefined)
                : undefined
            );
    }


    //
    // Private methods
    //
    private updateNotificationsList()
    {
        // This is required for ListData when notifications list is shown.  If it is computed
        // on-the-fly, Angular change detection burns CPU running Object.values() as it thinks
        // the object is different, even though the contents are unchanged
        //
        // SO, might as well sort by reverse date order here to allow for proper display order
        // (most recent notications first)
        //
        // Possible other mitigation might be to temporaily change the Angular changeDetection to OnPush
        this._notificationsV = Object.values(this.notifications)
            .sort((a: Notification, b: Notification): number =>
                b.date.valueOf() - a.date.valueOf()
            ); // sort
        this.notify();
    }
}