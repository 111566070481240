<div class="div" *ngIf="data">
    
        <qp-device-info
            [data]="data" [light]="light">
        </qp-device-info>

        <qp-table-data  *ngIf="d"
            [nullValues]=false [data]="
                [
                    { k: 'Sku',                 v: d.sku                  },
                    { k: 'Operator',            v: d.operator | titlecase },

                    d.cusnum > 0
                        ? { k: '# CUs',         v: d.cusnum               }
                        : {},

                    { k: 'Antenna',             v: d.antenna | titlecase  }
                ]">
        </qp-table-data>


    <!-- <mat-grid-list *ngIf="d.mobilecellsV && d.mobilecellsV.length > 0"
        [cols]="Media.isActive('lt-md') ? 1 : 2 " 
        [gutterSize]="'0.5em'"
        [rowHeight]="'1:1'"> -->
    <!-- <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="0.5em" > -->
        <div class="mobilecells" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="0.5em grid">
            <div *ngFor="let s of d.mobilecellsV" fxFlex>
                <!-- <div *ngFor="let s of d.mobilecellsV" fxFlex="1 1 calc(100%/2)" fxFlex.gt-lg="calc(100%/3) 1 calc(100%/3)"></div> -->
                <!-- <mat-grid-tile> -->
                    <qp-mobile-cell-info
                        [light]="light" [matCard]=true [cell]="s" [nullValues]=false>
                    </qp-mobile-cell-info>
                <!-- </mat-grid-tile> -->
            </div>
        </div>
    <!-- </mat-grid-list> -->
    <!-- <qp-mobile-cells-list *ngIf="d.mobilecellsV && d.mobilecellsV.length > 0" [attrs]="cellAttrs" [data]="d.mobilecellsV" (selected)="selectedFn($event)"></qp-mobile-cells-list> -->


        <div class="notifications" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="0.5em grid">
            <div *ngFor="let s of d.notificationsV" fxFlex>
                <qp-notification-info
                    [light]="light" [matCard]=true [data]="s" [nullValues]=false>
                </qp-notification-info>
            </div>
        </div>

<!-- 
    <div fxFlex="auto">
        <mat-grid-list *ngIf="d.notificationsV && d.notificationsV.length > 0"
            [cols]="Media.isActive('lt-md') ? 1 : 2 " 
            [gutterSize]="'0.5em'"
            [rowHeight]="'fit'">
            <div *ngFor="let s of d.notificationsV">
                <mat-grid-tile >
                    <qp-notification-info
                        [light]="light" [matCard]=true [data]="s" >
                    </qp-notification-info>
                </mat-grid-tile>
            </div>
        </mat-grid-list>
    </div> -->

</div>