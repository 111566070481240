<div (click)="onClick($event)"
    matTooltip="{{ 'Organisations' + (showBadge && this.num ? ': ' + this.num : '') }}">
    
    <button mat-button [color]="active ? buttonActive : ''">
        <!-- <mat-icon aria-label="Organisations view">business</mat-icon> -->
        <!-- <mat-icon aria-label="Organisations view">location_city</mat-icon> -->
        <mat-icon [ngClass]="showLabel ? '' : 'noLabel'" [matBadge]="num" [matBadgeColor]="badgeColour" [matBadgePosition]="badgePosition" [matBadgeSize]="badgeSize" [matBadgeHidden]="! showBadge || num <= 0" aria-label="Organisations view" [attr.ria-hidden]="! showBadge || num <= 0">
            {{ icon }}
        </mat-icon>
        <span class="cdk-visually-hidden">{{ num }}</span>

        <div class="sidebar-label" *ngIf="showLabel">Organisations</div>
    </button>
</div>