'use strict';


// Extend to be able to easily control the format that is used for display
export class Dateq extends Date
{
    static _timezonePlus  = "+";
    static _timezoneMinus = "-";
    static _zulu          = "Z";

    //
    // Getters
    //
    get displayStr()
    {
        return this.toLocaleString();
    }


    get isoStr()
    {
        return this.toISOString();
    }


    //
    // Static methods
    //

    // Note will return current datetime if null arg
    //static get(d?: number | string | Date): Dateq
    static get(d, now)
    {
        // Check if TZ info present; if not, add 'Z' for Zulu/UTC as all incoming dates should be UTC
        if (typeof d === "string"
            && ! d.includes(Dateq._timezonePlus)
            && ! d.includes(Dateq._timezoneMinus)
            && ! d.toUpperCase().endsWith(Dateq._zulu)) d += Dateq._zulu;

        try {
            return d ? new Dateq(d) : (now ? new Dateq() : undefined);
        }
        catch (e) {
            console.log("Exception creating Dateq object");
            console.log(e);
        }

        return undefined;
    }


    static getForDb(d, now)
    {
        d = Dateq._get(d, now);
        return (d instanceof Dateq)
            ? d.isoStr.split(Dateq._zulu)[0] // remove trailing Z
            : undefined;
    }


    static getForDbTz(d, now)
    {
        d = Dateq._get(d, now);
        let tz = undefined;
        if (d instanceof Dateq) {
            tz = d.isoStr.split(Dateq._timezonePlus)[1];
            if (! tz) tz = d.isoStr.split(Dateq._timezoneMinus)[1];
        }

        return tz;
        // return (d instanceof Dateq)
        //     ? d.isoStr.split(Dateq._timezone)[1] // remove trailing Z
        //     : undefined;
    }


    static now()
    {
        return Dateq.get(undefined, true);
    }


    //
    // Private methods
    //
    static _get(d, now)
    {
        return (! (d instanceof Date))
            ? Dateq.get(d, now)
            : d;
    }
}