import '@angular/compiler';
import { enableProdMode }            from '@angular/core';
import { platformBrowserDynamic }    from '@angular/platform-browser-dynamic';

//import "zone.js"
import './polyfills';

//import { setAngularJSGlobal }       from '@angular/upgrade/static';
//import { angular }                  from 'angular';

// import { AppModuleNg1 }              from './app/app.module.ng1';
// import './app/app.module-list.ng1'; // angularJS src files

import { AppModuleNg2 } from './app/app.module.ng2';


// allow 'noImplicitAny' to be true
//declare global {
//  const angular: typeof _angular_;
//}

//setAngularJSGlobal(angular);


// const development: string = "development";
const production:  string = "production";


console.debug("Running " + process.env.NODE_ENV + ": JiT compiled");

if (process.env.NODE_ENV === production) enableProdMode();

// Bootstrap the Angular 2+ module first, and then bootstrap the AngularJS (1) module
platformBrowserDynamic().bootstrapModule(AppModuleNg2)
    .then (ref => console.debug(ref))
    .catch(err => console.error(err));

// // Bootstrap the Angular 2+ module first, and then bootstrap the AngularJS (1) module
// console.log('Running JIT compiled');
// platformBrowserDynamic().bootstrapModule(AppModule)
//     .then(ref => {
//         // bootstrap AngularJS (1)
//         (<any>ref.instance).upgrade.bootstrap(document.body, [AppModuleNg1.name], { strictDi: true })
//     })
//     .catch(e => console.error(e));