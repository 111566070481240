export const routeNames: any = {
    dashboard:           'dashboard',
    device :             'device',
    // deviceChart:         'chart',
    devices:             'devices',
    devicegroups:        'devicegroups',
    login:               'login',
    map:                 'map',
    mobilecells:         'mobilecells',
    notifications:       'notifications',
    organisations:       'organisations',
    sites:               'sites',
    surveys:             'surveys',
    surveypoints:        'surveypoints',
    surveysightings:     'surveysightings',
    tests:               'tests',
    wifiaps:             'wifiaps',

    main:                '',

    securelogin:         'securelogin',
};
routeNames.main = routeNames.dashboard;