import { CommonModule }              from '@angular/common';
import { NgModule }                  from '@angular/core';
import { RouterModule }              from '@angular/router';

// mat-sidenav
import { MaterialCustomModule }      from '@Material/';

import { SideBarComponent }          from './sidebar.component';
import { SideBarContainerComponent } from './sidebar-container.component';

import { sidebarRoutes }             from './sidebar.routes';

import { ElementsModule }            from '../Elements/';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(sidebarRoutes), // needed for lazy loading

        ElementsModule,
        MaterialCustomModule
    ],
    
    declarations: [
        SideBarComponent,
        SideBarContainerComponent
    ],

    providers: [
    ],

    exports: [
        SideBarComponent,
    ]
})
export class SideBarModule { }