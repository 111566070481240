 <mat-dialog-content>
    <div class="buttonAndTextOuter">
<!--         <div class="buttonAndTextInner">
            <button mat-raised-button color="primary" (click)="buttonRefresh()" matTooltip="Reload">
                <mat-icon aria-label="Reload">refresh</mat-icon>
            </button>
        </div>

        <div class="buttonAndTextInner">
            <h3 mat-dialog-title>Notifications: {{ data.length }}</h3>
            <!--<h3 mat-dialog-title> Device Groups: {{ data.length }}</h3>-->
     <!--   </div> -->

<qp-window-header [title]="name" [value]="data.length" (refresh)="refresh(false)"></qp-window-header>

<!--         <div class="buttonAndTextInner2" style="min-width: 40%">
            <div class="input-group" style="width: 100%">
                <input type="text" class="form-control" placeholder="Search table" data-ng-model="search">
            </div>      
        </div> -->
    </div>

    <p>

    <!--<div flex="100" class="flex flex-col">
    <md-content md-theme="input" layout="column" >
    <md-input-container style="padding-bottom: 0; margin-bottom: 0">
    <md-icon class="material-icons">&#xE8B6;</md-icon>
    <input type="text" md-maxlength="50" data-ng-model="search" placeholder="Search table">
    </md-input-container>
    </md-content>
    </div>
    -->

    <div class="pagCtrl">
        <pagination-controls
            [autoHide]="true"
            [directionLinks]="true"
            (page-change)="pageChanged(newPageNumber, oldPageNumber)"
            [responsive]="true"
            id="'tab7'">
        </pagination-controls>
    </div>

    <div style="overflow-x: auto; vertical-align: middle;">
        <h2 *ngIf="data.length <=0">No data</h2>
        <table class="tab table" *ngIf="data.length > 0">
            <!--<caption>Connected Phones</caption>-->
            <thead>
                <tr>
                    <th></th> <!-- blank column heading for checkbox -->
                    <th *ngFor="let key of data[0]">
                        <button mat-button (click)="sortBy(key)" matTooltip="Click to change order">
                            <mat-icon aria-label="Change order">{{ propertyName == key && ! doReverse ? 'arrow_drop_down' : 'arrow_drop_up' }}</mat-icon>
                            <!-- 'titlecase' is Angular's built-in TitleCasePipe from CommonModule -->
                            {{ key | titlecase }}
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let notification of data | orderBy:propertyName.val:doReverse | paginate: { itemsPerPage: 20, currentPage: currentPage, id: 'tab7'}" (click)="showDetails($event, notification)">
                    <td *ngFor="let key of Object.keys(notification)">
                        {{ notification[key] }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="pagCtrl">
        <pagination-controls
            [autoHide]="true"
            [directionLinks]="true"
            (page-change)="pageChanged(newPageNumber, oldPageNumber)"
            [responsive]="true"
            id="'tab7'">
        </pagination-controls>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close color="primary" (click)="close()" matTooltip="Close">Close</button>
</mat-dialog-actions>