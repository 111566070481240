import {
    AfterViewInit,
    Component,
    OnInit,
    ViewChild
}                               from '@angular/core';
import {
    NavigationEnd,
    Router 
}                               from '@angular/router';
import {
    Observable,
    Subscription,

    filter
}                               from 'rxjs';

// import { NgbNavChangeEvent }    from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent }        from '@Base/';
import {
    User,
    UserService
}                               from '@Login/';
import { DataServiceEvents }    from '@Misc/Services/';
// import { MobileCellsService }   from '@MobileCells/';
// import { OrganisationsService } from '@Organisations/';
// import { WifiApsService }       from '@Wifi/';

import { TabsService }          from './tabs.service';


@Component({
   selector:     'qp-tabs',
   templateUrl:  'tabs.component.html',
   styleUrls:   ['tabs.component.css']
})
export class TabsComponent extends BaseComponent
     implements AfterViewInit, OnInit
{
    // @ViewChild(NgbTabset, {static: false})
    // private tabs: NgbTabset;

    private static readonly permittedRole              = "administrator";

    public         readonly currentOrientation: string = 'vertical';
    public                  data:               any;

    private                 _activatedRoute:    string = "";
    private                 _subRouter:         Subscription;
    private                 _user:              User;


    constructor(private readonly Router:      Router,
                private readonly TabsService: TabsService,
                private readonly UserService: UserService)
    {
        super();
    }


    // Override - don't let base class call this.initialise()
    public override ngOnInit(): void
    {
        // call this.initialise() in ngAfterContentInit() to ensure view fully set up
    }


    // OVerride
    public override ngAfterContentInit(): void
    {
        super.ngAfterContentInit();

        // Do here to call only once tabs view fully set up
        this.initialise();
    }


    //
    // Getters and setters
    //
    public get activatedRoute(): string
    {
        return this._activatedRoute;
    }

    private set activatedRouteI(d: string)
    {
        console.log("STU activated route");
        console.log(d)
        this._activatedRoute = d ? d : "";
    }


    //
    // Public methods
    //
    public gotPermission(): boolean
    {
        //return (user && user.userrole == TabsComponent.permittedRole);
        return (this._user != null);// [TBD] && user.userrole == TabsComponent.permittedRole);
    }


    // public tabChanged($event: NgbNavChangeEvent): void
    // {
    //     if ($event) {
    //         console.log("Setting tab: " + $event.nextId);
    //         this.TabsService.tabActiveReq = $event.nextId
    //     }
    // }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp()

        this._subRouter?.unsubscribe();
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Tabs component");

        // Subscribe to TabsService for updates
        // this.sub = this.TabsService.updatedTabActive.subscribe((id: string): void => {
        //     if (this.tabs && id) {
        //         console.log("Setting active tab: " + id);
        //         this.tabs.select(id);
        //     }
        // });

        // Subscribe for user updates
        let obs: Observable<any> | undefined = this.UserService.user$;
        if (obs instanceof Observable) this.sub = obs.subscribe((d: User): void => {
            this._user = d; 
        }); // subscribe()

        // // Subscribe to MobileCellsService for updates
        // obs = this.MobileCellsService[DataServiceEvents.number];
        // if (obs instanceof Observable) this.sub = obs.subscribe((num: number): void => {
        //     this.mobileCellsNum = num;
        // });

        // // Subscribe to OrganisationsService for updates
        // obs = this.OrganisationsService[DataServiceEvents.number];
        // if (obs instanceof Observable) this.sub = obs.subscribe((num: number): void => {
        //     this.organisationsNum = num;
        // });

        // // Subscribe to WifiService for updates
        // obs = this.WifiApsService[DataServiceEvents.number];
        // if (obs instanceof Observable) this.sub = obs.subscribe((num: number): void => {
        //     this.wifiApsNum = num;
        // });

        
        // Get current route url; needed by HTML
        this._activatedRoute = this.Router.url;
        this._subRouter = this.Router.events // seems to return SafeSubscriber, not Subscription
            .pipe(
                filter((event: any): boolean => event instanceof NavigationEnd)
            )
            .subscribe((d: NavigationEnd): void => {
                this.activatedRouteI = this.Router.url;
            }); // subscribe
    }
}