import { Routes }              from '@angular/router';

import { AuthenticationGuard } from '@Login/';

import { TabsComponent }       from './tabs.component';
import { elementsRoutes }      from '../Elements/elements.routes';


export const tabsRoutes: Routes = [
    {
        path:             '',
        component:        TabsComponent,
        canActivate:      [ AuthenticationGuard ],
        //canActivateChild: [ AuthenticationGuard ]
        children:         elementsRoutes
    }
];


// Need A2 component (downgraded and on index.html so AJS knows what to do), which includes router-outlet

// Sidebar currently being used, not tabs
