import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { MobileCellsService }   from '@MobileCells/mobile-cells.service';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
   selector:    'qp-sidebar-mobilecells',
   templateUrl: 'sidebar-mobilecells.component.html',
   styles:      'sidebar-mobilecells.component.css'
})
export class SideBarMobileCellsComponent extends SideBarBaseComponent
{
    constructor(MobileCellsService: MobileCellsService)
    {
        super(IconsMaterial.mobile_cells, MobileCellsService);
    }
}