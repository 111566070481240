import {
    BehaviorSubject,
    Observable
}                             from 'rxjs';

import { IconsService }       from '@Icons/';
import {
    Device,
    DeviceCommon
}                             from '../device/';

import { DeviceBeaconCommon } from './';


export class DeviceBeacon extends Device
{
    public override connectionType: string = "";
    public serialNum:      string = "";
    public ssid:           any    = null;


    protected _update$: BehaviorSubject<DeviceBeacon>;


    public constructor(data?: any, iconsService?: IconsService)
    {
        super(data, iconsService);

        this.merge(data);
        Object.assign(this, data);

        this._update$ = new BehaviorSubject<DeviceBeacon>(this);
    }


    // Factory
    public static get(d: any, iconsService?: IconsService)
    {
        return new DeviceBeacon(d, iconsService);
    }


    //
    // Getters and setters
    //
    public get notification(): Observable<DeviceBeacon>
    {
        return this._update$.asObservable();
    }


    // Override
    public get update$(): BehaviorSubject<any>
    {
        return this._update$;
    }


    //
    // Public methods
    //

    // Override
    public override infoWinContent(): string
    {
        let contentStr: String = super.infoWinContent();

        return "";
    }


    //
    // Protected methds
    //
      
    // Override
    protected override getIcon(cb: (d: any) => void): void 
    {
        return super.getIcon((d2: any) => {
            if (d2) {
                if(cb instanceof Function) return cb(d2);
            }
            else {
                // No icon
                if(cb instanceof Function) return cb(undefined);
            }
        }); // getIcon()
    }
}