import {
    Component,
    EventEmitter,
    // Directive,
    Inject,
    InjectionToken,
    Input,
    Output
}                        from '@angular/core';
import {
    Observable,
    Subscription,

    timer
}                        from 'rxjs';

import { BaseComponent } from '@Base/';
import {     
    MatBadgePosition,
    MatBadgeSize,
    ThemePalette
}                        from '@Material/';
import { 
    DataService,
    DataServiceEvents
}                        from '@Misc/Services/';


export const SideBarBaseComponentIcon       = new InjectionToken<string>('sideBarBaseComponentIcon');
export const SideBarBaseComponentUpdateIcon = new InjectionToken<boolean>('sideBarBaseComponentUpdateIcon');


@Component({
    template: ''
})
// @Directive()
export abstract class SideBarBaseComponent extends BaseComponent
{
    protected static readonly colourAccent:  string                   = "accent";
              static readonly colourPrimary: string                   = "primary";
    protected static readonly colourWarn:    string                   = "warn";
    private   static readonly timeout:       number                   = 5; // secs

    public                    colour:        any                      = SideBarBaseComponent.colourPrimary;
    // public                  num:     number                           = 0;

    private                   _num:          number;
    private                   _service:      DataService;
    private                   _timer:        Subscription | undefined = undefined;


    @Input()
    public                    active:        boolean                  = false;


    @Input()
    public                    badgeColour:   ThemePalette             = SideBarBaseComponent.colourPrimary as ThemePalette;


    @Input()
    public                    badgePosition: MatBadgePosition         = "after";//"above before";


    @Input()
    public                    badgeSize:     MatBadgeSize             = "small"; //"medium";


    @Input()
    public                    buttonActive:  string                   = SideBarBaseComponent.colourAccent;


    @Output()
    public           readonly selected:      EventEmitter<any>        = new EventEmitter();


    @Input()
    public                    showBadge:     boolean                  = false;


    @Input()
    public                    showExpansion: boolean                  = false;

    
    @Input()
    public                    showLabel:     boolean                  = false;
    
    
    constructor(@Inject(SideBarBaseComponentIcon)       public  readonly icon:        string,

                                                        private readonly Service?:    DataService,
                @Inject(SideBarBaseComponentUpdateIcon) private readonly updateIcon?: boolean)
    {
        super();
    }

    
    // Getters & setters
    public get num(): number
    {
        return this._num;
    }

    private set num(d: number)
    {
        this._num = d;
    }

    
    private get timerS(): Subscription | undefined
    {
        return this._timer;
    }

    private set timerS(t: Subscription | undefined)
    {
        this._clearTimer();
        this._timer = t;
    }


    //
    // Public methods
    //
    public onClick(event: any): void
    {
        this.selected.emit(event);
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp()

        this._clearTimer();
    }

    
    // Override
    protected override initialise(): void
    {
        super.initialise();

        if (this.Service instanceof DataService) {
            // Subscribe for updates
            const obs: Observable<any> | undefined = this.Service[DataServiceEvents.number];
            if (obs instanceof Observable) this.sub = obs.subscribe((d: number): void => {
                if (this.updateIcon) {
                    if (this.num != d) {
                        this.num = d;
                        this.colour = SideBarBaseComponent.colourAccent;
            
                        // Modify badge colour to indicate device number changed
                        this.timerS = timer(SideBarBaseComponent.timeout * 1000)
                            .subscribe(() => {
                                this.colour = SideBarBaseComponent.colourPrimary;
                                this.timerS = undefined;
                            }); // subscribe
                    }
                }
                else {
                    this.num = d;
                }
            }); // subscribe
        }
    }


    //
    // Private methods
    //
    private _clearTimer(timer: Subscription | undefined = this._timer): void
    {
        if (timer instanceof Subscription && ! timer.closed) timer.unsubscribe();
        timer = undefined;
    }
}