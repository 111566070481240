import { Routes }              from '@angular/router';

import { AuthenticationGuard } from '@Login/';

import { SideBarComponent }    from './sidebar.component';
// import { elementsRoutes }      from '../Elements/elements.routes';


export const sidebarRoutes: Routes = [
    {
        path:             '',
        component:        SideBarComponent,
        canActivate:      [ AuthenticationGuard ],
        //canActivateChild: [ AuthenticationGuard ]
        loadChildren: async () => (await import('@Navigation/Elements/elements.module-lazy-load')).ElementsModuleLazyLoad,
        // children:         elementsRoutes
    }
];


// @NgModule({
//     imports: [
//         // Note: only call RouterModule.forRoot in the root AppRoutingModule
//         RouterModule.forChild(routes)
//     ],

//     exports: [
//         RouterModule
//     ]
// })
// export class TabsRoutingModule { }