<div (click)="onClick($event)"
    matTooltip="Map">
    
    <button mat-button [color]="active ? buttonActive : ''">
        <mat-icon [ngClass]="showLabel ? '' : 'noLabel'" aria-label="Map view">
            {{ icon }}
        </mat-icon>

        <div class="sidebar-label" *ngIf="showLabel">Map</div>
    </button>
</div>