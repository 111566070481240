import {
    Component,
    Input
}                              from '@angular/core';

import {
    MobileCell,
    MobileCellLevelComponent
}                              from '../mobile-cell/';

import { MobileCellGsmCommon } from './';


@Component({
    selector:     'qp-mobile-cell-gsm-level',
    templateUrl:  'mobile-cell-gsm-level.component.html',
    styleUrls:   ['mobile-cell-gsm-level.component.css'],
})
export class MobileCellGsmLevelComponent extends MobileCellLevelComponent
{
    //
    // Getters
    //

    public static override getLevelToolTip(d: MobileCell): string
    {
        return super.getLevelToolTip(d)
            + (d instanceof MobileCellGsmCommon
                ? (d.rssi ? "\nRssi: " + d.rssi : "")
                    + (d.rxqual ? "\nRxqual: " + d.rxqual  : "")
                
                : ""
            );
    }
}