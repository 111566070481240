<div class="div" *ngIf="d">

    <!-- <qp-element-info [data]="data" [light]="light" [showType]=showType [showUpdated]=true></qp-element-info> -->
    <qp-element-info [data]="data" [light]="light" ></qp-element-info>

    <!-- <p></p>
    
    <h4 class="mat-subheading-1">{{ data.tag | titlecase }}</h4> -->
    <!-- <mat-list dense> -->

    <qp-table-data *ngIf="d" [nullValues]=false [data]="
        [
            { k: 'Point Name', v: d.name    | titlecase },
            { k: 'Point Id',   v: d.id + ''             }
        ]">
    </qp-table-data>


<!-- <qp-surveysightings *ngIf="data && data.sightings && data.sightings.length > 0"
    [data]=data.sightings [parentId]=+data.id>
</qp-surveysightings> -->
</div>