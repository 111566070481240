import { Component }            from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../../element/element/element-info.component';

import { DeviceWithBattery }    from './device-with-battery.class';


@Component({
    selector:     'qp-device-with-battery-info',
    templateUrl:  'device-with-battery-info.component.html',
    styleUrls:   ['device-with-battery-info.component.css'],
})
export class DeviceWithBatteryInfoComponent extends ElementInfoComponent
{
    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }

    
    //
    // Getters
    //
    public get d(): DeviceWithBattery
    {
        return this.data as DeviceWithBattery;
    }
}