import {
    BehaviorSubject,
    Observable
}                    from 'rxjs';

import { Messages }  from '@Messaging/';
import { Utils }     from '@Utils/';

import { Element }   from '../../element/element/element.class';


export class DeviceWithBattery extends Element
{
    private static readonly batteryLevelDP:    number  = 0;

    private                 _batteryCharging:  boolean = false;
    private                 _batteryConnected: string  = "";
    private                 _batteryLevel:     number  = -1;

    private _update$: BehaviorSubject<DeviceWithBattery>;


    public constructor(data?: any)
    {
        super();

        if (data) this.merge(data);
        
        this._update$ = new BehaviorSubject<DeviceWithBattery>(this);
    }


    //
    // Static methods
    //
    public static get(data?: any): DeviceWithBattery | undefined
    {
        return data ? new DeviceWithBattery(data) : undefined;
    }


    //
    // Getters and setters
    //
    public get batteryCharging(): boolean
    {
        return this._batteryCharging;
    }

    public set batteryCharging(d: boolean)
    {
        this.setAttr('_batteryCharging', d);
        // if (d != null) {
        //     this._batteryCharging = d;
        //     this.notify();
        // }
    }

    public get batteryChargingStr(): string
    {
        return this._batteryCharging.toString();
    }


    public get batteryConnected(): string
    {
        return this._batteryConnected;
    }

    public set batteryConnected(d: string)
    {
        this.setAttr('_batteryConnected', d);
        // if (d != null) {
        //     this._batteryConnected = d;
        //     this.notify();
        // }
    }


    public get batteryLevel(): number
    {
        return this._batteryLevel;
    }

    private get batteryLevelI(): string
    {
        return this.batteryLevel.toString();
    }

    private set batteryLevelI(d: string)
    {
        this.setAttr('_batteryLevel', d);
        // if (d != null) {
        //     this._batteryLevel = parseFloat(d);
        //     this.notify();
        // }
    }

    public get batteryLevelStr(): string
    {
        return (typeof this.batteryLevel !== 'undefined' && this.batteryLevel != null && this.batteryLevel >= 0)
            ? this.batteryLevel.toFixed(DeviceWithBattery.batteryLevelDP) + " %"
            : "Unknown";
    }


    public get notification(): Observable<DeviceWithBattery>
    {
        return this.update.asObservable();
    }


    private get update(): BehaviorSubject<DeviceWithBattery>
    {
        return this._update$;
    }

    protected override get update$(): BehaviorSubject<any>
    {
        return this._update$;
    }


    //
    // Public methods
    //

    // Override
    public override cleanUp(): void
    {
        super.cleanUp();

        if (this.update) this.update.complete();
    }


    // Override
    public override infoWinContent(): string
    {
        let contentStr: string = super.infoWinContent();

        contentStr += "<br><br><b><b><i>Battery</i></b></b>";
        contentStr += "<br><b>Level</b>: "  + this.batteryLevelStr;

        if (this.batteryCharging && this.batteryConnected && this.batteryConnected != 'none') {
            contentStr += "<br><b>Charging</b>: " + this.batteryConnected;
        }

        return contentStr;
    }


    // Override
    public override merge(d: object): Element
    {
        super.merge(d);

        if (d) {
            const bc = this.getAttr(d, this.attr.batterycharging);
            if (bc) this.batteryCharging = Utils.primitiveToBoolean(bc);

            this.setAttr2('batteryConnected', this.getAttr(d, this.attr.batteryconnected));
            this.setAttr2('batteryLevelI',    this.getAttr(d, this.attr.batterylevel));
        }

        return this;
    }


    //
    // Protected methods
    //
      
    // Override
    protected getIcon(cb: (d: any) => void): void 
    {
        // No icon
        if (cb) cb(undefined);
    }


    //
    // Private methods
    //
    private get attr(): any
    {
        return Messages.attributes;
    }
}