import { Component }            from '@angular/core';

import { ElementInfoComponent } from '../../../element/element/element-info.component';

import { ConnectionCommon }     from '@Common/Elements/Devices/';


@Component({
    selector:     'qp-connection-info',
    templateUrl:  'connection-info.component.html',
    styleUrls:   ['connection-info.component.css'],
})
export class ConnectionInfoComponent extends ElementInfoComponent
{
    //
    // Getters
    //
    public get d(): ConnectionCommon
    {
        return this.data as ConnectionCommon;
    }
}