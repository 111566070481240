import { VersionCommon } from '@Common/Version';

import VerJson           from '../../../version.json';//'../../../version.json';


export class Version extends VersionCommon
{
    public static get(): any
    {
        VersionCommon.set(VerJson);
        return this;
    }
}