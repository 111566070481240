import { CommonModule }                  from '@angular/common';
import { NgModule }                      from '@angular/core';

// mat-sidenav
import { MaterialCustomModule }          from '@Material/';

import { SideBarMenuComponent }          from './sidebar-menu.component';

import { SideBarMapComponent }           from './sidebar-map.component';
import { SideBarDashboardComponent }     from './sidebar-dashboard.component';
import { SideBarDeviceGroupsComponent }  from './sidebar-devicegroups.component';
import { SideBarDevicesComponent }       from './sidebar-devices.component';
import { SideBarMobileCellsComponent }   from './sidebar-mobilecells.component';
import { SideBarOrganisationsComponent } from './sidebar-organisations.component';
import { SideBarSurveysComponent }       from './sidebar-surveys.component';
import { SideBarWifiApsComponent }       from './sidebar-wifiaps.component';


@NgModule({
    imports: [
        CommonModule,

        MaterialCustomModule,
    ],
    
    declarations: [
        SideBarDashboardComponent,
        SideBarDeviceGroupsComponent,
        SideBarDevicesComponent,
        SideBarMapComponent,
        SideBarMenuComponent,
        SideBarMobileCellsComponent,
        SideBarOrganisationsComponent,
        SideBarSurveysComponent,
        SideBarWifiApsComponent
    ],

    providers: [
    ],

    exports: [
        SideBarDashboardComponent,
        SideBarDeviceGroupsComponent,
        SideBarDevicesComponent,
        SideBarMapComponent,
        SideBarMenuComponent,
        SideBarMobileCellsComponent,
        SideBarOrganisationsComponent,
        SideBarSurveysComponent,
        SideBarWifiApsComponent
    ]
})
export class ElementsModule { }