import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { DeviceGroupsService }  from '@DeviceGroups/device-groups.service';

import { SideBarBaseComponent } from './sidebar-base.component';

import { AppStrings }           from '@Root/';


@Component({
   selector:    'qp-sidebar-devicegroups',
   templateUrl: 'sidebar-devicegroups.component.html',
   styles:      'sidebar-devicegroups.component.css'
})
export class SideBarDeviceGroupsComponent extends SideBarBaseComponent
{
    public readonly name = AppStrings.DeviceGroups;


    constructor(DeviceGroupsService: DeviceGroupsService)
    {
        super(IconsMaterial.device_groups, DeviceGroupsService); // [TBD]
    }
}