<div (click)="onClick($event)"
    matTooltip="{{ 'Mobile Cells' + (showBadge && this.num ? ': ' + this.num : '') }}">

    <button mat-button [color]="active ? buttonActive : ''">
        <mat-icon [ngClass]="showLabel ? '' : 'noLabel'" [matBadge]="num" [matBadgeColor]="badgeColour" [matBadgePosition]="badgePosition" [matBadgeSize]="badgeSize" [matBadgeHidden]="! showBadge || num <= 0" aria-label="Mobile Cells view">
            {{ icon }}
        </mat-icon>
        <span class="cdk-visually-hidden">{{ num }}</span>

        <div class="sidebar-label" *ngIf="showLabel">Mobile Cells</div>
    </button>
</div>