import { MobileNetworks as MobileNetworksCommon } from '@Common/';

import MobileNetworksJson       from '@Common/mobile-networks.json';


export class MobileNetworks extends MobileNetworksCommon
{
    public static get(): any
    {
        MobileNetworksCommon.set(MobileNetworksJson);
        return this;
    }
}