import { CommonModule } from '@angular/common';
import { NgModule     } from '@angular/core';
import { RouterModule } from '@angular/router';

// @Directive({selector: 'qp-mobile-cells'})
// export class AngularJSComponent4 extends UpgradeComponent
// {
//     constructor(private readonly ref: ElementRef,
//                 private readonly inj: Injector)
//     {
//         super('qpMobileCells', ref, inj);
//     }
// }


@NgModule({
    imports: [
        CommonModule,
        // RouterModule.forChild(mobilecellsRoutes), // needed for lazy loading
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class MobileNetworksModule { }