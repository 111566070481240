import { Component }            from '@angular/core';

import { ElementInfoComponent } from '../../../element/element/element-info.component';

import { PowerCommon }          from '@Common/Elements/Devices/';


@Component({
    selector:     'qp-power-info',
    templateUrl:  'power-info.component.html',
    styleUrls:   ['power-info.component.css'],
})
export class PowerInfoComponent extends ElementInfoComponent
{
    //
    // Getters
    //
    public get d(): PowerCommon
    {
        return this.data as PowerCommon;
    }
}