import { IconsService }      from '@Icons/';

import {
    Device,
    DeviceI,
    DeviceCommon
}                            from './device/';
import { DeviceBeacon }      from './device-beacon/';
import { DeviceMobilePhone } from './device-mobilephone/';
import { DeviceRepeater }    from './device-repeater/';
import { DeviceWithBattery } from './device-with-battery/';
import { DeviceWithWifi }    from './device-with-wifi/';
import { Sim }               from './sim/';


export class DeviceHelper
{
    public static get(d:             Device | DeviceI, 
                      iconsService?: IconsService,
                      offline:       boolean = false): Device | undefined

    {
        if (d instanceof Device) return d;

        let fn: Function | undefined = undefined;

        switch (DeviceHelper.getType(d)) {
            case DeviceCommon.Types.mobilephone:
                fn = DeviceMobilePhone.get;
            break;

            case DeviceCommon.Types.repeater:
                fn = DeviceRepeater.get;
            break;

            case DeviceCommon.Types.beacon:
                fn = DeviceBeacon.get;
            break;

            default:
                // Do nothing
        } // switch

        return (fn instanceof Function)
            ? fn(d, iconsService)
            : undefined;
    }


    // public static getInfoType(d: Element): any// typeof ElementInfoComponent
    // {
    //     if (d instanceof Element) {
    //         if      (d instanceof DeviceMobilePhone) return DeviceMobilePhoneInfoComponent;
    //         else if (d instanceof DeviceRepeater)    return DeviceRepeaterInfoComponent;
    //         else if (d instanceof DeviceBeacon)      return DeviceBeaconInfoComponent;
    //         else if (d instanceof DeviceWithBattery) return DeviceWithBatteryInfoComponent;
    //         else if (d instanceof DeviceWithWifi)    return DeviceWithWifiInfoComponent;
    //         else if (d instanceof Sim)               return SimInfoComponent;
    //     }

    //     return undefined;
    // }


    // Required as some objects will be send with '_type' instead of 'type'
    private static getType(d: DeviceI): any
    {
        return (d && DeviceCommon.attrs._type in Object(d))
            ? d._type
            // ? d[DeviceCommon.attrs._type]
            : undefined;
    }
}