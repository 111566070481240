<div class="div" *ngIf="d" (click)="selectedFn($event)">

    <qp-element-info
        [data]="data" [light]="light" [showType]=showType (selected)="selectedFn($event)">
    </qp-element-info>

    <!-- <p></p>
    
    <h4 class="mat-subheading-1">{{ data.tag | titlecase }}</h4> -->
    <!-- <mat-list dense> -->
    <!-- <mat-list dense>
        <qp-data-element-item [light]="light" [key]="'Name'"    [text]="d.name">   </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Address'" [text]="d.address"></qp-data-element-item>
    </mat-list> -->

    <qp-table-data *ngIf="d"
        [nullValues]=false [data]="
            [
                showName
                    ? { k: 'Name', v: data.name | titlecase }
                    : {},

                { k: 'Address',    v: d.address             }
            ]">
    </qp-table-data>
</div>