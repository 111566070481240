import {
    Component,
    Input
}                                from '@angular/core';

import { ElementInfoComponent }  from '../../element/element/element-info.component';

import { MobileCellNrnsaCommon } from './';


@Component({
    selector:     'qp-mobile-cell-nrnsa-info',
    templateUrl:  'mobile-cell-nrnsa-info.component.html',
    styleUrls:   ['mobile-cell-nrnsa-info.component.css']
})
export class MobileCellNrnsaInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:        any;

    @Input()
    public dataSource2: any;

    @Input()
    public nullValues:  boolean = true;

    
    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }
}