import {
    Component,
    Input
}                             from '@angular/core';

import {
    MobileCell,
    MobileCellLevelComponent
}                             from '../mobile-cell/';

import { MobileCellNrCommon } from './';


@Component({
    selector:     'qp-mobile-cell-nr-level',
    templateUrl:  'mobile-cell-nr-level.component.html',
    styleUrls:   ['mobile-cell-nr-level.component.css'],
})
export class MobileCellNrLevelComponent extends MobileCellLevelComponent
{
    //
    // Getters
    //

    public static override getLevelToolTip(d: MobileCell): string
    {
        return super.getLevelToolTip(d)
            + (d instanceof MobileCellNrCommon
                ? (d.rsrpss ? "\nRsrp Ss: " + d.rsrpss : "")
                    + (d.rsrqss ? "\nRsrq Ss: " + d.rsrqss  : "")
                    + (d.sinrss ? "\nSinr Ss: " + d.sinrss  : "")
                
                : ""
            );
    }
}