import {
    Observable,
    Subscription,

    // delay,
    take,
    // interval,
    timer
}                 from 'rxjs';


export class Utils
{
    // Returns observable if no fn, else Subscription
    public static delayMsecs(fn: Function, msecs: number = 0): Observable<number> | Subscription
    {
        const obs: Observable<number> = timer(msecs);
        return fn instanceof Function && obs instanceof Observable
            ? obs
                .pipe(take(1))
                .subscribe((d): void => {
                    fn();
                }) // subscribe

            : obs;
    }


    public static delaySecs(fn: Function, secs: number = 0): Observable<number> | Subscription
    {
        return Utils.delayMsecs(fn, secs * 1000);
    }


    public static primitiveToBoolean(value: string | number | boolean | null | undefined): boolean
    {
        if (value === 'true') return true;

        return(
            (typeof value === 'string')
            ? !!+value // parse string to integer first
            : !!value
        );
    }


    public static stringCapFirstLetter(str: string): string
    {
        const newStr: string = (str && str.length > 0) ? str.charAt(0).toUpperCase() : str;

        return (str.length > 1) ? newStr + str.slice(1) : newStr;
    }
}