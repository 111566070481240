import {
    BehaviorSubject,
    Observable
}                       from 'rxjs';

import { DeviceHelper } from '@Common/Elements/Devices/';

import { Element }      from '../../element//element/element.class';


export class DeviceWithWifi extends Element
{
    private static readonly rssiMin: number = -114;

    private _bssid: string = "";
    private _rssi:  number = DeviceWithWifi.rssiMin;
    private _ssid:  string = "";

    private _update$: BehaviorSubject<DeviceWithWifi>;


    public constructor(data?: any)
    {
        super(DeviceHelper.field);

        if (data) this.merge(data);
        
        this._update$ = new BehaviorSubject<DeviceWithWifi>(this);
    }


    //
    // Static methods
    //
    public static get(data?: any): DeviceWithWifi | undefined
    {
        if (data)  {
            const wifi = new DeviceWithWifi(data);
            if (wifi.bssid) return wifi;
        }

        return undefined;
    }


    //
    // Getters and setters
    //
    public get bssid(): string
    {
        return this._bssid;
    }

    public set bssid(d: string)
    {
        if (d != null) {
            this._bssid = d;
            this.notify();
        }
    }


    public get notification(): Observable<DeviceWithWifi>
    {
        return this.update.asObservable();
    }


    public get rssi(): number
    {
        return this._rssi;
    }


    private set rssiI(d: string)
    {
        if (d != null) {
            this._rssi = parseFloat(d);
            this.notify();
        }
    }


    public get rssiMin(): number
    {
        return DeviceWithWifi.rssiMin;
    }

    
    public get ssid(): string
    {
        return this._ssid;
    }

    public set ssid(d: string)
    {
        if (d != null) {
            this._ssid = d;
            this.notify();
        }
    }


    private get update(): BehaviorSubject<DeviceWithWifi>
    {
        return this._update$;
    }

    protected override get update$(): BehaviorSubject<any>
    {
        return this._update$;
    }


    //
    // Public methods
    //

    // Override
    public override cleanUp(): void
    {
        super.cleanUp();

        if (this.update) this.update.complete();
    }


    // Override
    public override infoWinContent(): string
    {
        let contentStr: string = "";

        contentStr += "<br><br><b><b><i>Wifi</i></b></b>";
        contentStr += "<br><b>Ssid</b>: " + this.ssid;
        if (this.rssi > DeviceWithWifi.rssiMin) contentStr += "<br><b>Rssi</b>: "  + this.rssi;

        return contentStr;
    }


    // Override
    public override merge(d: any): Element
    {
        super.merge(d);

        if (d) {
            if (d.bssid    != null) this.bssid = d.bssid;
            if (d.wifirssi != null) this.rssiI = d.wifirssi;
            if (d.ssid     != null) this.ssid  = d.ssid;
        }

        return this;
    }


    //
    // Protected methds
    //
      
    // Override
    protected getIcon(cb: (d: any) => void): void 
    {
        // No icon
        if (cb) cb(undefined);
    }

    // protected notify(): void
    // {
    //     if (this.update) this.update.next(this);
    // }
}