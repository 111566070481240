<div class="div" *ngIf="d">

    <p></p>

    <h4 class="mat-subheading-1">Sim {{ simNum ? simNum : (d.slot > 1 ? d.slot : "") }}</h4>
    <mat-list dense>
        <qp-data-element-item *ngIf="d.imei"   [light]="light" [key]="'Imei'"             [text]="'' + d.imei">                                                             </qp-data-element-item>
        <qp-data-element-item *ngIf="d.imsi"   [light]="light" [key]="'Imsi'"             [text]="'' + d.imsi">                                                             </qp-data-element-item>
        <qp-data-element-item *ngIf="d.msisdn" [light]="light" [key]="'Telephone number'" [text]="d.msisdn">                                                                </qp-data-element-item>
        <qp-data-element-item *ngIf="d.plmn"   [light]="light" [key]="'Network'"          [text]="d.operator + ' (' + d.operatorCountry + ' : ' + d.plmn + ')' | titlecase"></qp-data-element-item>
    </mat-list>

</div>