import { Base }         from '@Base/';
import { IconsService } from '@Icons/';

import { Notification } from './';


export class NotificationHelper extends Base
{
    public static get(data?:         Notification | any,
                      iconsService?: IconsService): Notification
    {
        return (data instanceof Notification)
            ? data
            : this.getNotification(data, iconsService);
    }


    // public static getInfoType(d: MobileCell): typeof NotificationInfoComponent
    // {
    //     if (d) {
    //         if      (d instanceof Notification) 
    //             return NotificationInfoComponent;
    //     }

    //     return undefined;
    // }


    private static getNotification(data?: any, iconsService?: IconsService): Notification
    {
        return new Notification(data, iconsService);
    }
}