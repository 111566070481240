import {
    Component,
    Input
}                               from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../element/element/element-info.component';

import {
    Survey,
    SurveyPoint
}                               from './';

import { routeNames }           from '../../app.routing.names';


@Component({
    selector:     'qp-survey-info',
    templateUrl:  'survey-info.component.html',
    styleUrls:   ['survey-info.component.css']
})
export class SurveyInfoComponent extends ElementInfoComponent
{
    @Input()
    public showName: boolean = true;


    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): Survey
    {
        return this.data as Survey; // [TBD]
    }

    
    //
    // Public methods
    //
    
    public mobilecellsArray(): Object[]
    {
        return this.d instanceof Survey && Array.isArray(this.d.mobilecells)
            ? this.d.mobilecells.sort((a, b) => a.simslot - b.simslot)
            : [];
    }


    public selectedFn(d2: any): Promise<boolean> | undefined
    {

this.select(d2);
return undefined;
        // onst ret: Promise<boolean> = this.Router.navigate(
        //     SurveysComponent.outlet
        //         ? [ {outlets: {[SurveysComponent.outlet]: [(row as Survey).id ]}} ]
        //         : [ (row as Survey).id ],
                
        //     { relativeTo: this.ActRoute.parent }
        // ) // navigate()


        return (d2 instanceof SurveyPoint && (d2 as SurveyPoint).id !== undefined)
            // Route to surveypoint
            ? this.Router.navigate(
                // [ this.d.id, routeNames.surveypoints, (d2 as SurveyPoint).id ],
                [ {outlets: {'primary': [this.d.id, routeNames.surveypoints, (d2 as SurveyPoint).id ]}} ],
                { 
                    relativeTo: this.ActRoute.parent,
                    // state: { parent: this.Router.url } 
                }
            ) // navigate()

            // Route to main page [TBD]
            : ((d2 instanceof Survey && (d2 as Survey).id !== undefined)
                ? this.Router.navigate(
                    [ routeNames.surveys, (d2 as Survey).id ],
                    { 
                        state: { parent: this.Router.url } 
                    }
                ) // navigate()
                
                : undefined
            );
    }
}