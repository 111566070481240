import { Base }                                    from '@Base/';

// import {
//     MobileCell,
//     MobileCellLevelComponent
// }                                                  from './mobile-cell/';
// import {
//     MobileCellCdmaCommon as MobileCellCdma,
//     MobileCellCdmaLevelComponent
// }                                                  from './mobile-cell-cdma/';
// import {
//     MobileCellGsmCommon as MobileCellGsm,
//     MobileCellGsmLevelComponent
// }                                                  from './mobile-cell-gsm/';
// import {
//     MobileCellLteCommon as MobileCellLte,
//     MobileCellLteLevelComponent
// }                                                  from './mobile-cell-lte/';
// import {
//     MobileCellNrCommon as MobileCellNr,
//     MobileCellNrLevelComponent
// }                                                  from './mobile-cell-nr/';
// import {
//     MobileCellNrnsaCommon as MobileCellNrnsa,
//     MobileCellNrnsaLevelComponent
// }                                                  from './mobile-cell-nrnsa/';
// import {
//     MobileCellTdscdmaCommon as MobileCellTdscdma,
//     MobileCellTdscdmaLevelComponent
// }                                                  from './mobile-cell-tdscdma/';
// import {
//     MobileCellUmtsCommon as MobileCellUmts,
//     MobileCellUmtsLevelComponent
// }                                                  from './mobile-cell-umts/';


export class TestHelper2 extends Base
{
    // Factory methods
    // public static mergeOrGet(c: MobileCell, d?: any): MobileCell | undefined
    // {
    //     if (! d) return c;

    //     // Check if technology has changed
    //     return (
    //         c && this.getTechnology(d) === c.technology
    //             ? c._merge(d)
    //             : this.get(d)
    //     );
    // }


    //
    // Public methods
    //
    // public static getLevelToolTip(d: MobileCell): string
    // {
    //     let fn: Function | undefined = undefined;
    //     if (d instanceof MobileCell) {
    //         if      (d instanceof MobileCellCdma)    fn = MobileCellCdmaLevelComponent.getLevelToolTip;
    //         else if (d instanceof MobileCellGsm)     fn = MobileCellGsmLevelComponent.getLevelToolTip;
    //         else if (d instanceof MobileCellLte)     fn = MobileCellLteLevelComponent.getLevelToolTip;
    //         else if (d instanceof MobileCellNr)      fn = MobileCellNrLevelComponent.getLevelToolTip;
    //         else if (d instanceof MobileCellNrnsa)   fn = MobileCellNrnsaLevelComponent.getLevelToolTip;
    //         else if (d instanceof MobileCellTdscdma) fn = MobileCellTdscdmaLevelComponent.getLevelToolTip;
    //         else if (d instanceof MobileCellUmts)    fn = MobileCellUmtsLevelComponent.getLevelToolTip;
    //         else                                     fn = MobileCellLevelComponent.getLevelToolTip;
    //     }

    //     return fn instanceof Function
    //         ? fn(d)
    //         : "";
    // }


    // public static getInfoType(d: MobileCell): typeof ElementInfoComponent | undefined
    // {
    //     if (d instanceof MobileCell) {
    //         if      (d instanceof MobileCellCdma)    return MobileCellCdmaInfoComponent;
    //         else if (d instanceof MobileCellGsm)     return MobileCellGsmInfoComponent;
    //         else if (d instanceof MobileCellLte)     return MobileCellLteInfoComponent;
    //         else if (d instanceof MobileCellNr)      return MobileCellNrInfoComponent;
    //         else if (d instanceof MobileCellNrnsa)   return MobileCellNrnsaInfoComponent;
    //         else if (d instanceof MobileCellTdscdma) return MobileCellTdscdmaInfoComponent;
    //         else if (d instanceof MobileCellUmts)    return MobileCellUmtsInfoComponent;

    //     }

    //     return undefined;
    // }


    //
    // Private methods
    //
    // private static get(d: MobileCell | any): MobileCell | undefined
    // {
    //     if (d instanceof MobileCell) return d;

    //     let fn: Function | undefined = undefined;
    //     switch (MobileCell.Technology.get(this.getTechnology(d)) ) {
    //         case MobileCell.Technology.Cdma:
    //             fn = MobileCellCdma.get;
    //         break;

    //         case MobileCell.Technology.Gsm:
    //             fn = MobileCellGsm.get;
    //         break;

    //         case MobileCell.Technology.Lte:
    //             fn = MobileCellLte.get;
    //         break;

    //         case MobileCell.Technology.Nr:
    //             fn = MobileCellNr.get;
    //         break;

    //         case MobileCell.Technology.Nr_nsa:
    //             fn = MobileCellNrnsa.get;
    //         break;

    //         case MobileCell.Technology.Td_scdma:
    //             fn = MobileCellTdscdma.get;
    //         break;

    //         case MobileCell.Technology.Umts:
    //             fn = MobileCellUmts.get;
    //         break;

    //         default:
    //             // No default
    //     } // switch
        
    //     return fn instanceof Function
    //         ? fn(d)
    //         : undefined;
    // }


    // private static getTechnology(d?: any): string
    // {
    //     return d
    //         ? (d.rat
    //           ? d.rat
    //           : (d._technology
    //              ? d._technology
    //              : undefined
    //             )
    //         )

    //        : undefined;
    // }
}