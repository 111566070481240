import { Injectable }  from '@angular/core';
import {
    ActivatedRoute,
    Router
}                      from '@angular/router';
import {
    BehaviorSubject,
    Observable
}                      from 'rxjs';

import { BaseService } from '@Base/';


@Injectable({
    providedIn: 'any'
})
export class SideBarService extends BaseService
{
    private _tabActive:        string | undefined                  = "";//SideBarService.tabs.dashboard;
    private _tabActiveSubject: BehaviorSubject<string | undefined>;

    // Need to keep this in sync with sidebar HTML
    public static readonly Tabs = new Map([
        [ 'dashboard',     'dashboard'     ],
        [ 'organisations', 'organisations' ],
        [ 'surveys',       'surveys'       ],
        [ 'map',           'map'           ], 
        [ 'mobilecells',   'mobilecells'   ],
        [ 'wifi',          'wifi'          ]
    ]); // Tabs

    constructor(private readonly ActRoute: ActivatedRoute,
                private readonly Router:   Router) 
    { 
        super();
    }


    // Getters and setters
    private get tabActive(): string |  undefined
    {
        return this._tabActive;
    }


    private set tabActive(id: string | undefined)
    {
        this._tabActive = id;
    }

 
    public set tabActiveReq(id: string)
    {
console.log(id + ", " + this.tabActive);
        if (id && SideBarService.Tabs.get(id) && this.tabActive != SideBarService.Tabs.get(id)) {
            console.log("Switching tab: " + SideBarService.Tabs.get(id));
            this.tabActive = SideBarService.Tabs.get(id);
console.log(this._tabActiveSubject?.value);
            this._tabActiveSubject?.next(this.tabActive);
console.log(this._tabActiveSubject?.value);

            this.ActRoute.data.subscribe((data: any): void => {
                if (data && data.id != id) {
                    console.log("Changed route to match tab: " + data.id + ", " + id);
                    this.Router.navigate([this.tabActive]);
                }
                //this.id = +params['id']; // (+) converts string 'id' to a number
            }); // subscribe()
        }
    }


    public get updatedTabActive(): Observable<string | undefined> | undefined
    {
        return this._tabActiveSubject?.asObservable();
    }


    //
    // Public Functions
    //
    public getTab(id: string): string | undefined
    {
        return id ? SideBarService.Tabs.get(id) : "";
    }


    //
    // Protected Functions
    //
    protected override initialise(): void
    {
        super.initialise();

        console.log("Initialising Tabs service");

        this._tabActiveSubject = new BehaviorSubject<string | undefined>(this.tabActive);
    }
}