import { Component }            from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../element/element/element-info.component';

import { Site }                 from './';

import { routeNames }           from '../../app.routing.names';


@Component({
    selector:     'qp-site-info',
    templateUrl:  'site-info.component.html',
    styleUrls:   ['site-info.component.css']
})
export class SiteInfoComponent extends ElementInfoComponent
{
    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): Site
    {
        return this.data as Site;
    }


    public selectedFn(d: any): Promise<boolean> | undefined
    {
        // Now route to main site page
        return d instanceof Site && (d as Site).id
            ? this.Router.navigate(
                [ routeNames.organisations + "/" + routeNames.sites, (d as Site).id ],
                { state: { parent: this.Router.url } }
            )
            : undefined;
    }
}