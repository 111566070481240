<div class="div" *ngIf="d">
    
    <p></p>

    <h4 class="mat-subheading-1">Battery</h4>
    <mat-list dense>
        <qp-data-element-item [light]="light" [key]="'Level'"    [text]="d.batteryLevelStr">                                                             </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Charging'" [text]="d.batteryCharging && d.batteryConnected !== 'none' ? d.batteryConnected : 'No'"></qp-data-element-item>
    </mat-list>

</div>