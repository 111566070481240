import { Routes }              from '@angular/router';

import { AuthenticationGuard } from '@Login/';

import { routeNames }          from '../../app.routing.names';


export const elementsRoutes: Routes = [
    { 
        path:        '',
        redirectTo:  routeNames.dashboard,
        pathMatch:   'full'
    },

    { 
        path:        routeNames.dashboard,
        // component:   DashboardComponent,
        canActivate: [ AuthenticationGuard ],
        data:        {
            id:      routeNames.dashboard,
            preload: true
        },
        loadChildren: async () => (await import('@Dashboard/dashboard.module-lazy-load')).DashboardModuleLazyLoad,
        // loadChildren: () => import('@Dashboard/dashboard.module').then(m => m.DashboardModule)
    },

    
    // { 
    //     path:        routeNames.dashboard,
    //     canActivate: [ AuthenticationGuard ],
    //     data:        { id: routeNames.dashboard },
    //     loadChildren: async () => (await import('@Dashboard/dashboard.module')).DashboardModule,
    // },

    {
        path:        routeNames.devices,
        canActivate: [ AuthenticationGuard ],
        data:        { id: routeNames.devices },
        loadChildren: async () => (await import('@Devices/devices.module-lazy-load')).DevicesModuleLazyLoad,   // better - needs Ivy
    },

    {
        path:        routeNames.devicegroups,
        canActivate: [ AuthenticationGuard ],
        data:        { id: routeNames.devicegroups },
        loadChildren: async () => (await import('@DeviceGroups/device-groups.module-lazy-load')).DeviceGroupsModuleLazyLoad,
    },
    
    { 
        path:        routeNames.map,
        canActivate: [ AuthenticationGuard ],
        data:        { id: routeNames.map },
        loadChildren: async () => (await import('@Map/map.module-lazy-load')).MapModuleLazyLoad,
    },

    { 
        path:        routeNames.mobilecells,
        canActivate: [ AuthenticationGuard ],
        data:        { id: routeNames.mobilecells },
        loadChildren: async () => (await import('@MobileCells/mobile-cells.module-lazy-load')).MobileCellsModuleLazyLoad,
    },

    {
        path:        routeNames.organisations,
        canActivate: [ AuthenticationGuard ],
        data:        { id: routeNames.organisations },
        loadChildren: async () => (await import('@Organisations/organisations.module-lazy-load')).OrganisationsModuleLazyLoad,
    },

    {
        path:        routeNames.surveys,
        canActivate: [ AuthenticationGuard ],
        data:        { id: routeNames.surveys },
        loadChildren: async () => (await import('@Surveys/surveys.module-lazy-load')).SurveysModuleLazyLoad,
    },

    { 
        path:        routeNames.wifiaps,
        canActivate: [ AuthenticationGuard ],
        data:        { id: routeNames.wifiaps },
        loadChildren: async () => (await import('@Wifi/wifiaps.module-lazy-load')).WifiApsModuleLazyLoad,
    }
];