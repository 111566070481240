<div class="div" *ngIf="d">

    <qp-element-info [data]="d" [light]="light" [showDescription]=true [showType]=showType [showUpdated]=true></qp-element-info>

    <!-- <p></p>
    
    <h4 class="mat-subheading-1">{{ data.tag | titlecase }}</h4> -->
    <!-- <mat-list dense> -->

        <!-- { k: 'Description',         v: d.description            | titlecase }, -->

    <qp-table-data *ngIf="d" [nullValues]=false [data]="
        [
            showName
                ? { k: 'Name',                v: d.name                   | titlecase }
                : {},
            { k: 'Type',                      v: d.type                   | titlecase },
            showTestProfile
                ? { k: 'Profile id',          v: d.testprofileId                      }
                : {},
            showTestProfile
                ? { k: 'Profile description', v: d.testprofileDescription | titlecase }
                : {},
            d.start
                ? { k: 'Start',               v: d.start + ''                         }
                : {},
            d.end
                ? { k: 'End',                 v: d.end + ''                           }
                : {},
            (d.duration !== undefined)
                ? { k: 'Duration',            v: d.duration + ' secs'                 }
                : {},
        ]">
    </qp-table-data>

</div>