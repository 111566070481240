<div class="div" *ngIf="d" (click)="selectedFn($event)">

    <qp-element-info
        [data]="data" [light]="light" [showType]=showType (selected)="selectedFn($event)">
    </qp-element-info>

    <!-- <p></p>
    
    <h4 class="mat-subheading-1">{{ data.tag | titlecase }}</h4> -->
    <!-- <mat-list dense> -->
    <!-- <mat-list dense>
        <qp-data-element-item [light]="light" [key]="'Name'"         [text]="d.name">           </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Owner'"        [text]="d.owner">          </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Address'"      [text]="d.address">        </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Phone number'" [text]="d.telephoneNumber"></qp-data-element-item>
    </mat-list> -->

    <qp-table-data *ngIf="d"
            [nullValues]=false [data]="
            [
                { k: 'Name',         v: d.name            | titlecase },
                { k: 'Phone number', v: d.telephoneNumber             },
                { k: 'Address',      v: d.addressStr                  }
            ]">
    </qp-table-data>
    <!-- { k: 'Owner',        v: d.owner           | titlecase }, -->

</div>