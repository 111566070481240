<!-- <div class="content" *ngIf="data"> -->
<div *ngIf="data">
    <qp-element-info
        [data]="data" [light]="light" [showId]=true [showUpdated]=true (selected)="selectedFn($event)">
    </qp-element-info>


    <qp-table-data *ngIf="d"
        [nullValues]=false [data]="
            [
                { k: 'Type',           v: d.type         | titlecase             },
                { k: 'Manufacturer',   v: d.manufacturer | titlecase             },
                { k: 'Model Name',     v: d.modelName    | titlecase             },
                { k: 'Model',          v: d.model        | titlecase             },

                d.load
                    ? { k: 'Software', v: d.deviceType + ' ' + d.load | titlecase}
                    : {},

                { k: 'Status',         v: d.status       | titlecase             },
                { k: 'Connected',      v: d.connectedDate                        },
                { k: 'Reconnected',    v: d.reconnectedDate                      },
                { k: 'Connection',     v: d.connectionType                       },
                { k: 'Version',        v: d.appSoftwareVersion                   }
            ]">
    </qp-table-data>
    

    <!-- <qp-table-data *ngIf="d" [nullValues]=true [data]="
    [
        { k: 'Action',       v: d.action                                      }
    ]">
</qp-table-data> -->

    <!-- <h4 class="mat-subheading-1">Device</h4> -->
        <!-- <mat-list dense> -->
<!-- <mat-list dense> -->
        <!-- <qp-data-element-item                           [light]="light" [key]="'Type'"         [text]="d.deviceType | titlecase"> </qp-data-element-item>
        <qp-data-element-item                           [light]="light" [key]="'Manufacturer'" [text]="d.manufacturer | titlecase">    </qp-data-element-item>
        <qp-data-element-item *ngIf="d.modelName"       [light]="light" [key]="'Model Name'"   [text]="d.modelName | titlecase">       </qp-data-element-item>
        <qp-data-element-item                           [light]="light" [key]="'Model'"        [text]="d.model | titlecase">           </qp-data-element-item>
        <qp-data-element-item *ngIf="d.load"            [light]="light" [key]="'Software'"     [text]="d.deviceSubType + ' ' + d.load"></qp-data-element-item>
        <qp-data-element-item                           [light]="light" [key]="'Status'"       [text]="d.status | titlecase">          </qp-data-element-item>
        <qp-data-element-item *ngIf="d.connectedDate"   [light]="light" [key]="'Connected'"    [text]="d.connectedDate">               </qp-data-element-item>
        <qp-data-element-item *ngIf="d.reconnectedDate" [light]="light" [key]="'Reconnected'"  [text]="d.reconnectedDate">             </qp-data-element-item> -->
        <!-- <mat-list-item>                              <b>Manufacturer:</b>{{ d.manufacturer    }}</mat-list-item>
        <mat-list-item>                              <b>Model:</b>       {{ d.model           }}</mat-list-item>
        <mat-list-item>                              <b>Id:</b>          {{ d.id              }}</mat-list-item>

        <mat-list-item *ngIf="load">   <b>Software:</b>    {{ d.deviceSubType   }} {{ d.load }}</mat-list-item>
        <mat-list-item>                <b>Status:</b>      {{ d.status          }}</mat-list-item> -->

        <!-- <div *ngIf="d.status == ElementStatus.Online"> -->
            <!-- <mat-list-item *ngIf="d.connectedDate">  <b>Connected:</b>   {{ d.connectedDate   }}</mat-list-item>
            <mat-list-item *ngIf="d.reconnectedDate"><b>Reconnected:</b> {{ d.reconnectedDate }}</mat-list-item> -->
        <!-- </div> -->

<!-- <div *ngIf="d.connectionType">
    
    <div *ngIf="d.connectionType === 'wifi' && d.wifi">
        <qp-device-with-wifi-info [light]="light" [data]=d.wifi></qp-device-with-wifi-info>
    </div>
</div>
</mat-list> -->
</div>
<!-- <qp-data-element-item [light]="light" [key]="'Connection'" [text]="d.connectionType" ></qp-data-element-item> -->
    <!-- <mat-list-item><b>Connection:</b> {{ d.connectionType }}</mat-list-item> -->