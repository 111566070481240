import { CommonModule }                  from '@angular/common';
import { NgModule }                      from '@angular/core';
import { FlexLayoutModule }              from '@angular/flex-layout';

// qp-list-data, qp-mat-list-element, qp-refresh-button, qp-status-indicator, qp-title, qp-window-header
import { GuiElementsModule }             from '@GuiElements/';
import { MaterialCustomModule }          from '@Material/';

// qp-mobile-cells-list
// import { MobileCellsListModule }         from '../MobileCells/MobileCellsList/mobile-cells-list.module';
// import { MobileCellsListModule }         from '@MobileCells/mobile-cells-list.module';


import { DeviceGroupInfoComponent }      from './device-group/';
import {
    ConnectionInfoComponent,
    DeviceInfoContainerComponent,
    DeviceInfoComponent,
    DeviceBeaconInfoComponent,
    DeviceMobilePhoneInfoComponent,
    DeviceRepeaterInfoComponent,
    DeviceWithBatteryInfoComponent,
    DeviceWithWifiInfoComponent,
    PowerInfoComponent,
    SimInfoComponent
}                                        from './devices/';
    
import { ElementInfoComponent }          from './element/element/';
import { ElementInfoContainerComponent } from './element/';
    
import {
    MobileCellInfoComponent,
    MobileCellCdmaInfoComponent,
    MobileCellGsmInfoComponent,
    MobileCellLteInfoComponent,
    MobileCellNrInfoComponent,
    MobileCellNrnsaInfoComponent,
    MobileCellTdscdmaInfoComponent,
    MobileCellUmtsInfoComponent
}                                        from './mobile-cells/';

import { NotificationInfoComponent }     from './notification/';

import { OrganisationInfoComponent }     from './organisation/';

import { SiteInfoComponent }             from './site/';

import {
    SurveyInfoComponent,
    SurveyPointCellsOrderPipe,
    SurveyPointInfoComponent,
    SurveySightingInfoComponent
}                                        from './survey/';

import {
    TestDataRatePipe,
    TestDataSizePipe,
    TestInfoComponent,
    TestFtpInfoComponent,
    TestIcmpInfoComponent
}                                        from './test/';


import { WifiCellInfoComponent }         from './wifi-cells/';

import { ObjectElementsComponent }       from './object-elements.component';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        
        GuiElementsModule,
        MaterialCustomModule,
        // MobileCellsListModule
    ],
    
    declarations: [
        DeviceGroupInfoComponent,

        ConnectionInfoComponent,
        DeviceInfoContainerComponent,
        DeviceInfoComponent,
        DeviceBeaconInfoComponent,
        DeviceMobilePhoneInfoComponent,
        DeviceRepeaterInfoComponent,
        DeviceWithBatteryInfoComponent,
        DeviceWithWifiInfoComponent,
        PowerInfoComponent,

        ElementInfoComponent,
        ElementInfoContainerComponent,

        MobileCellInfoComponent,
        MobileCellCdmaInfoComponent,
        MobileCellGsmInfoComponent,
        MobileCellLteInfoComponent,
        MobileCellNrInfoComponent,
        MobileCellNrnsaInfoComponent,
        MobileCellTdscdmaInfoComponent,
        MobileCellUmtsInfoComponent,

        NotificationInfoComponent,

        ObjectElementsComponent,

        OrganisationInfoComponent,
    
        SimInfoComponent,
    
        SiteInfoComponent,

        SurveyInfoComponent,
        SurveyPointCellsOrderPipe,
        SurveyPointInfoComponent,
        SurveySightingInfoComponent,

        TestDataRatePipe,
        TestDataSizePipe,
        TestInfoComponent,
        TestFtpInfoComponent,
        TestIcmpInfoComponent,

        WifiCellInfoComponent
    ],

    providers: [
    ],

    exports: [
        DeviceGroupInfoComponent,

        ConnectionInfoComponent,
        DeviceInfoContainerComponent,
        DeviceInfoComponent,
        DeviceBeaconInfoComponent,
        DeviceMobilePhoneInfoComponent,
        DeviceRepeaterInfoComponent,
        DeviceWithBatteryInfoComponent,
        DeviceWithWifiInfoComponent,
        PowerInfoComponent,

        ElementInfoComponent,
        ElementInfoContainerComponent,

        MobileCellInfoComponent,
        MobileCellGsmInfoComponent,
        MobileCellLteInfoComponent,
        MobileCellNrInfoComponent,
        MobileCellNrnsaInfoComponent,
        MobileCellUmtsInfoComponent,

        NotificationInfoComponent,

        OrganisationInfoComponent,
    
        SimInfoComponent,
    
        SiteInfoComponent,

        SurveyInfoComponent,
        SurveyPointCellsOrderPipe,
        SurveyPointInfoComponent,
        SurveySightingInfoComponent,

        TestInfoComponent,
        TestFtpInfoComponent,
        
        WifiCellInfoComponent
    ]
})
export class ObjElementsModule { }