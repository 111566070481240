import {
    Component,
    Input
}                               from '@angular/core';
// import {
//     ActivatedRoute,
//     Router 
// }                               from '@angular/router';

import { ElementInfoComponent } from '../../element/element/element-info.component';
// import { MobileCell }           from '@ObjElements/';

import {
    Survey,
    SurveyPoint,
    SurveySighting
}                               from '../';

import { routeNames }           from '../../../app.routing.names';


@Component({
    selector:     'qp-survey-point-info',
    templateUrl:  'surveypoint-info.component.html',
    styleUrls:   ['surveypoint-info.component.css']
})
export class SurveyPointInfoComponent extends ElementInfoComponent
{
    @Input()
    public noCells: boolean = false;


    //
    // Getters
    //
    public get d(): SurveyPoint
    {
        return this.data as SurveyPoint; // [TBD]
    }

    
    //
    // Public methods
    //
    public selectedFn(d: any): Promise<boolean> | undefined
    {
        // Now route to main page
        return d instanceof Survey && (d as Survey).id
            ? this.Router.navigate(
                [ routeNames.surveys, (d as Survey).id ],
                { state: { parent: this.Router.url } }
            )
            : undefined;

            // this.Router.navigate([ routeNames.map ], { queryParams: { 'element': Survey.tag, 'id': this.data.id } });
    }


//     onURLinserted() {
//         this.getImage(myURL).subscribe(data => {
//           this.createImageFromBlob(data);
//         }, error => {
//           console.log("Error occured",error);
//         });
//   }
  
//   getImage(imageUrl: string): Observable<File> {
//       return this.http
//           .get(imageUrl, { responseType: ResponseContentType.Blob })
//           .map((res: Response) => res.blob());
//   }
  
//   createImageFromBlob(image: Blob) {
//      let reader = new FileReader(); //you need file reader for read blob data to base64 image data.
//      reader.addEventListener("load", () => {
//         this.imageToShow = reader.result; // here is the result you got from reader
//      }, false);
  
//      if (image) {
//         reader.readAsDataURL(image);
//      }
//   }
}