import { CommonModule }   from '@angular/common';
import { NgModule }       from '@angular/core';
import { RouterModule }   from '@angular/router';

import { ElementsModule } from './elements.module';

import { elementsRoutes } from './elements.routes';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(elementsRoutes), // needed for lazy loading

        ElementsModule
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class ElementsModuleLazyLoad { }