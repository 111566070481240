import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { WifiApsService }       from '@Wifi/wifiaps.service';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
   selector:    'qp-sidebar-wifiaps',
   templateUrl: 'sidebar-wifiaps.component.html',
   styles:      'sidebar-wifiaps.component.css'
})
export class SideBarWifiApsComponent extends SideBarBaseComponent
{
    constructor(WifiApsService: WifiApsService)
    {
        super(IconsMaterial.wifi, WifiApsService);
    }
}