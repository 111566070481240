import { Injectable }     from '@angular/core';
import { 
    BehaviorSubject,
    Observable
}                         from 'rxjs';

import { MessageService } from '@Messaging/';
import {
    DataService,
    DataServiceEvents
}                         from '@Misc/Services/';
import {
    Organisation,
    Site
}                         from '@ObjElements/';


@Injectable({
    providedIn: 'root'
})
export class OrganisationsService extends DataService
{
    protected static readonly _title: string = "Organisations";

    // protected                 _dataElementsC$: BehaviorSubject<Organisation[]>;


    public constructor(MessageService: MessageService)
    {
        super(MessageService, OrganisationsService._title, MessageService.messages.msgTypesSub.organisations);

        // this._dataElementsC$ = new BehaviorSubject<Organisation[]>([]);

         // Don't call from within initialise() as that is called by parent constructor and this.MessageService not available then
        this.refresh(true);

        // Subscribe to service for updates
    //     const obs: Observable<any> | undefined = this[DataServiceEvents.data];
    //     if (obs instanceof Observable) this.sub = obs.subscribe((d: Organisation[]): void => {
    //         if (Array.isArray(d)) this._dataElementsC$.next(d.map(e => e.elementC)); // Return array of OrganisationCommons
    //     }); // subscribe
    }

    
    //
    // Interface
    //

    // Override
    public get(id: string | number): object
    {
        return this.getOrganisation(id as string);
    }
    

    //
    // Getters
    //
    // public get [DataServiceEvents.data + "C"](): Observable<Organisation[]>
    // {
    //     // If size but no elements, trigger full data re-load
    //     // if (this.size > this.sizeI && this.msgType) this.refresh(true);

    //     return this._dataElementsC$.asObservable();
    // }


    
    //
    // Public methods
    //
    public getOrganisation(id: string): Organisation
    {
        return this.getElement(id) as Organisation;
    }


    public getSite(id: number): Site | undefined
    {
        for (const o of this.dataE) {
            if (o && o.sites && Array.isArray(o.sites)) {
                for (const s of o.sites) {
                    if (s && s.id == id) return s;
                } // for
            }
        } // for
        
        return undefined;
    }


    // public refresh2(): Subscription
    // {
    //     // Delay so only visible if significant delay in msg response
    //     this.dataLoading$.next(true); // used by component to trigger spinner

    //     const obs: Observable<any> = this.MessageService.sendMsg(
    //         this.MessageService.messages.msgTypes.getdata,
    //         {
    //             type: this.MessageService.messages.msgTypesSub.organisations
    //         }
    //     );

    //     return (obs)
    //         ? (this.sub = obs
    //             .pipe(take(1))
    //             .subscribe((data: any): void => {
    //                 this.update(data && data.data ? data.data : data);
    //                 this.dataLoading$.next(false);  // used by component to trigger spinner
    //             })
    //           )  
    //         : null;
    // }

    // Override
    protected override processMsgData(d: any[]): void
    {
        super.processMsgData(d);

        if (Array.isArray(d)) {
            this.clear(); // clear existing stored data
            Object.entries(d).forEach(([k, v]): void => {
                const o: Organisation | undefined = Organisation.get(v);
                if (o instanceof Organisation) this.add(o.id, o, false);
            }); // forEach
            this.updateObservables();
        }
        else {
            console.log("No data received to update " + this.Title + " service");
        }
    }
}